import React, {FC, useCallback, useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  LoadingSpinner,
  MagicWandIcon,
  TAndCIcon,
} from 'components';
import {FormSelect, FormTextArea} from 'components/Basic/Form/V2';
import {
  GroupedOptions,
  OtherClinicalConditionsOptions,
  PromptRelatedQuestions,
} from 'definitions/OpenAI';
import {GenerateNoteFormProps, GenerateNoteSchema} from 'definitions/Yup';
import {
  selectTherapyQuestionnaire,
  selectTherapyQuestionnaireStatus,
} from 'features/Provider/Members/membersSelectors';
import {selectUserProfile} from 'features/User';
import {useQuery, useRequesting} from 'hooks';
import {
  MemberProfile,
  OpenAIPrompt,
  OpenAIResponse,
  OptionType,
  SliceStatus,
} from 'interfaces';
import log from 'loglevel';
import {useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {copyToClipboard} from 'utils';

import {yupResolver} from '@hookform/resolvers/yup';

import {providerActions} from '../providerSlice';

import {getNotesList} from './notesSelectors';
import {ActionContainer} from './styled';

type Props = {
  goToCreatedNote: (content: OpenAIResponse) => void;
  member: MemberProfile;
};

const GenerateNote: FC<Props> = ({goToCreatedNote, member}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [noteFailed, setNoteFailed] = useState(false);
  const [noteCreationDelay, setNoteCreationDelay] = useState(false);
  const [hasPreviousInputs, setHasPreviousInputs] = useState(false);
  const [previousInputsResponse, setPreviousInputsResponse] =
    useState<OpenAIPrompt>({
      mentalHealthConditions: [],
      otherClinicalConditions: [],
      socioDemographics: [],
      otherIssues: '',
    });
  const [socioDemographicsOptions, setSocioDemographicsOptions] = useState<
    OptionType[]
  >([]);
  const dispatch = useDispatch();
  const urlParams = useQuery();
  const appointmentIDFromParam = urlParams.get('appointmentID') ?? '';

  const {t} = useTranslation();

  const appointmentID = appointmentIDFromParam || '';

  const user = useSelector(selectUserProfile);

  const status = useRequesting('provider');
  const isLoading = status === SliceStatus.pending;

  const notes = useSelector(getNotesList);
  const isFirstNote = notes.length == 0;
  const [isSocioDemographicsEnabled, setIsSocioDemographicsEnabled] =
    useState<boolean>(isFirstNote);

  const getIntakeQTherapyQuestionnaire = useCallback(() => {
    dispatch(
      providerActions.getTherapyQuestionnaire({
        patientId: member.patientId,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getIntakeQTherapyQuestionnaire();
  }, [getIntakeQTherapyQuestionnaire]);

  const questions = useSelector(selectTherapyQuestionnaire) as {
    Id: string;
    Text: string;
    Answer: string;
  }[];
  const TherapyQuestionnaireLoadingStatus = useSelector(
    selectTherapyQuestionnaireStatus,
  );
  const isTherapyQuestionnaireLoading =
    TherapyQuestionnaireLoadingStatus === SliceStatus.pending;

  useEffect(() => {
    if (!isSocioDemographicsEnabled || !questions) {
      setSocioDemographicsOptions([]);
      setValue('socioDemographics', []);
      return;
    }
    const requestFailed =
      TherapyQuestionnaireLoadingStatus === SliceStatus.rejected;
    if (requestFailed) {
      return;
    }
    const extractedQuestionnaire = preFillSocioDemographics(questions);
    setSocioDemographicsOptions([...extractedQuestionnaire]);
    setValue('socioDemographics', extractedQuestionnaire);
  }, [questions, isSocioDemographicsEnabled]);

  const preFillSocioDemographics = (
    questions: {
      Id: string;
      Text: string;
      Answer: string;
    }[],
  ): OptionType[] => {
    try {
      const extractedQuestionnaire: OptionType[] = [];
      const maxKeysCount = PromptRelatedQuestions.length;
      let filledKeysCount = 0;
      const excludedKeywords = ['no', 'none', 'no .* problems'];

      questions.forEach(item => {
        if (filledKeysCount >= maxKeysCount) {
          return;
        }

        const question = item.Text;
        const answer = item.Answer;
        const isExcluded = excludedKeywords.some(
          excludedKeyword =>
            !answer ||
            answer?.length === 0 ||
            !!RegExp(excludedKeyword).exec(answer.toLowerCase()),
        );

        if (isExcluded) {
          return;
        }

        for (const promptRelatedQuestion of PromptRelatedQuestions) {
          if (
            question
              .toLowerCase()
              .includes(promptRelatedQuestion.questionPartialText.toLowerCase())
          ) {
            const extractedAnswerLimited =
              promptRelatedQuestion.prompt.split(/\s+/).slice(0, 4).join(' ') +
              ': ' +
              answer; // take only first four words of the prompt
            const extractedAnswer =
              promptRelatedQuestion.prompt + ': ' + answer;
            extractedQuestionnaire.push({
              label: extractedAnswerLimited,
              value: extractedAnswer,
            });
            filledKeysCount++;
          }
        }
      });
      return extractedQuestionnaire;
    } catch (error) {
      log.error(error);
      return [];
    }
  };

  const handleCheckChange = (isChecked: boolean) => {
    setIsSocioDemographicsEnabled(isChecked);
  };

  const {control, handleSubmit, setValue, getValues} =
    useForm<GenerateNoteFormProps>({
      mode: 'all',
      resolver: yupResolver(GenerateNoteSchema),
      defaultValues: {
        mentalHealthConditions: [],
        otherClinicalConditions: [],
        socioDemographics: [],
        otherIssues: '',
      },
    });

  const prefillInputs = (previousInputs: OpenAIPrompt) => {
    setIsInitialLoading(false);
    if (previousInputs.mentalHealthConditions) {
      setHasPreviousInputs(true);
      setPreviousInputsResponse(previousInputs);
    }
  };

  useEffect(() => {
    setIsInitialLoading(true);
    setTimeout(() => {
      setIsInitialLoading(false);
    }, 2500);
    dispatch(
      providerActions.getGeneratedNotePreviousInputs({
        userRole: user!.role,
        patientId: member?.patientId,
        prefillInputs(previousInputs) {
          prefillInputs(previousInputs);
        },
      }),
    );
  }, []);

  useEffect(() => {
    handlePreviousInputsChange(true);
  }, [previousInputsResponse]);

  const handlePreviousInputsChange = (isChecked: boolean) => {
    const transformArrayToOptions = (arr: string[]) => {
      if (arr) {
        return arr.map(item => ({
          label: item,
          value: item,
        }));
      }
      return arr;
    };
    if (isChecked) {
      const previousMentalHealthConditions = transformArrayToOptions(
        previousInputsResponse.mentalHealthConditions,
      );
      const previousOtherClinicalConditions = transformArrayToOptions(
        previousInputsResponse.otherClinicalConditions as string[],
      );
      const previousSocioDemographics = transformArrayToOptions(
        previousInputsResponse.socioDemographics as string[],
      );
      setValue('mentalHealthConditions', previousMentalHealthConditions);
      setValue('otherClinicalConditions', previousOtherClinicalConditions);
      setValue('socioDemographics', previousSocioDemographics);
      setValue('otherIssues', previousInputsResponse.otherIssues);
    } else {
      setValue('mentalHealthConditions', []);
      setValue('otherClinicalConditions', []);
      setValue('socioDemographics', []);
      setValue('otherIssues', '');
    }
  };

  const onNoteGenerateFailed = () => {
    copyToClipboard(getValues().otherIssues);
    setNoteFailed(true);
  };

  const onSubmit = (data: GenerateNoteFormProps) => {
    setNoteFailed(false);
    setNoteCreationDelay(false);
    setTimeout(() => {
      setNoteCreationDelay(true);
    }, 10000);

    dispatch(
      providerActions.generateNote({
        prompt: {
          mentalHealthConditions:
            data.mentalHealthConditions.map(val => val!.value) ?? [],
          otherClinicalConditions:
            data.otherClinicalConditions?.map(val => val?.value) ?? [],
          socioDemographics:
            data.socioDemographics?.map(val => val?.value) ?? [],
          otherIssues: data.otherIssues,
        },
        userRole: user!.role,
        patientId: member?.patientId,
        appointmentID: appointmentID,
        preferredLanguage: user?.preferredLanguage,
        goToCreatedNote(content) {
          goToCreatedNote(content);
        },
        onNoteGenerateFailed,
        isFailedToast: data.otherIssues.length,
        isToast: true,
        toastMsg: t('noteGeneratedSuccessfully', 'Note generated successfully'),
      }),
    );
  };

  return (
    <section className="relative w-full">
      {t('noteGeneratedSuccessfully', 'Note generated successfully')}
      <ActionContainer className=" flex items-center justify-between px-3">
        <div className="flex items-center">
          {status === SliceStatus.pending ? (
            <LoadingSpinner height={20} type="Oval" color="#000" />
          ) : null}
        </div>
        {status === SliceStatus.pending ? (
          <p className="text-xs text-gray-600">{t('noteGenerating...')}</p>
        ) : null}
      </ActionContainer>
      <form
        id="generate-note-form"
        className="pl-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="px-6 pb-8">
          {hasPreviousInputs ? (
            <Checkbox
              label={t('usePreviousInput')}
              onChange={handlePreviousInputsChange}
              defaultChecked={true}
            />
          ) : null}
          <FormSelect
            id="mentalHealthConditions"
            name="mentalHealthConditions"
            label={t('mentalHealthConditions')}
            control={control}
            isMulti
            isSearchable
            options={GroupedOptions(t)}
            creatable
            placeholder={t('mentalHealthConditions')}
          />
          <FormSelect
            id="otherClinicalConditions"
            name="otherClinicalConditions"
            label={t('otherClinicalConditions')}
            control={control}
            options={OtherClinicalConditionsOptions(t)}
            isMulti
            creatable
            placeholder={t('otherClinicalConditions')}
          />
          {!isFirstNote && (
            <Checkbox
              label={t('useSocio-Demographics')}
              onChange={handleCheckChange}
            />
          )}
          {isSocioDemographicsEnabled && (
            <FormSelect
              id="socioDemographics"
              name="socioDemographics"
              label={t('socioDemographics')}
              control={control}
              options={socioDemographicsOptions}
              isMulti
              creatable
              placeholder={t('socioDemographics')}
              isLoading={isTherapyQuestionnaireLoading}
              noOptionsMessage={() => t('typeToAddSocio-Demographics')}
            />
          )}
          <FormTextArea
            id="otherIssues"
            name="otherIssues"
            label={t('addFewSpecificDetails')}
            control={control}
            placeholder={t('addDetails')}
            classes="rounded bg-white"
          />
          <div className="py-2 mt-4 mb-8 w-full flex ">
            {isLoading && !isInitialLoading ? (
              <MagicWandIcon
                width={50}
                height={50}
                fillColor="#315eff"
                strokeColor="text-white"
                classes="animate-beatPulse"
              />
            ) : (
              <Button
                type="submit"
                borderColor="transparent"
                disabled={isLoading || isTherapyQuestionnaireLoading}
                className="px-5 py-2 mt-4 mb-4 flex ml-auto items-center justify-center hover:bg-white hover:text-white border-blue-600 rounded-full"
              >
                {t('generateMagicNotes')}
              </Button>
            )}
          </div>
          <div>
            {noteCreationDelay && !noteFailed ? (
              <p className="text-base text-center text-gray-500 font-light">
                <Trans i18nKey="noteGenerating" t={t}>
                  It may take a minute for the magic to happen..
                </Trans>
              </p>
            ) : null}
            {noteFailed ? (
              <p className="text-base mt-2 text-center text-red-500">
                {t('noteGenerationFailed')}
              </p>
            ) : null}
          </div>
        </div>
      </form>
      {isInitialLoading ? (
        <section className="w-full h-full bg-white bg-opacity-80 absolute top-0 left-0 z-999 flex flex-col justify-center items-center">
          <div className="w-2/3 flex flex-col justify-center items-center">
            <TAndCIcon
              width={70}
              height={70}
              fillColor="#315eff"
              strokeColor="text-white"
              classes="animate-bounce"
            />
            <p className="p-4 text-xl text-center text-gray-800 font-light">
              {t('preparingNote')}
            </p>

            {noteCreationDelay ? (
              <p className="text-base text-center text-gray-700 font-light">
                {t('takingABitLonger')}
              </p>
            ) : null}
          </div>
        </section>
      ) : null}
    </section>
  );
};
export default GenerateNote;
