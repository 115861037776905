import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of, tap} from 'rxjs';
import {catchError, filter, ignoreElements, mergeMap} from 'rxjs/operators';
import {MagicContentService} from 'services';

import {magicContentActions} from './magicContentSlice';

const generateExpertSummaryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateExpertSummary.match),
    mergeMap(({payload: {onSuccess}}) =>
      from(MagicContentService.generateExpertSummary()).pipe(
        mergeMap(res =>
          of(
            magicContentActions.generateExpertSummarySuccess(
              res.data.message.expertSummary,
            ),
          ).pipe(tap(() => onSuccess(res.data.message.expertSummary))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.generateExpertSummaryFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateExpertSummaryFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateExpertSummaryFailure.match),
    tap(({payload}) => {
      Toast({
        type: 'error',
        message: `${payload}. Try editing your bio in profile settings.`,
      });
    }),
    ignoreElements(),
  );

const generateMagicContentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateMagicContent.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(MagicContentService.generateMagicContent(payload)).pipe(
        mergeMap(res =>
          of(
            magicContentActions.generateMagicContentSuccess(
              res.data.message.content,
            ),
          ).pipe(tap(() => onSuccess?.())),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.generateMagicContentFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateMagicContentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateMagicContentFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const reGenerateMagicContent: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.reGenerateMagicContent.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(MagicContentService.reGenerateMagicContent(payload)).pipe(
        mergeMap(res =>
          of(
            magicContentActions.reGenerateMagicContentSuccess({
              ...res.data.message.content,
              element: payload.element,
            }),
          ).pipe(tap(() => onSuccess?.(res.data.message.content))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(
              magicContentActions.reGenerateMagicContentFailure({
                error: message,
                element: payload.element,
              }),
            ),
          ),
        ),
      ),
    ),
  );

const reGenerateMagicContentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateMagicContentFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const saveMagicArticleEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(magicContentActions.saveMagicArticle.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(MagicContentService.saveMagicArticle(payload)).pipe(
        mergeMap(res =>
          of(
            magicContentActions.saveMagicArticleSuccess(
              res.data.message.article,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message.article))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.saveMagicArticleFailure(message)),
          ),
        ),
      ),
    ),
  );

const saveMagicArticleFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.saveMagicArticleFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getMagicImagesEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicImages.match),
    mergeMap(({payload}) =>
      from(MagicContentService.getMagicImages(payload)).pipe(
        mergeMap(res => [
          magicContentActions.getMagicImagesSuccess(res.data.message.photos),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.getMagicImagesFailure(message)),
          ),
        ),
      ),
    ),
  );

const getMagicImagesFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicImagesFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getMagicArticleBySlugEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicArticleBySlug.match),
    mergeMap(({payload}) =>
      from(MagicContentService.getMagicArticleBySlug(payload)).pipe(
        mergeMap(res => [
          magicContentActions.getMagicArticleBySlugSuccess(
            res.data.message.article,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.getMagicArticleBySlugFailure(message)),
          ),
        ),
      ),
    ),
  );

const getMagicArticleBySlugFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicArticleBySlugFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getMagicArticlesByProviderEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicArticlesByProvider.match),
    mergeMap(({payload}) =>
      from(MagicContentService.getMagicArticlesByProvider(payload)).pipe(
        mergeMap(res => [
          magicContentActions.getMagicArticlesByProviderSuccess(
            res.data.message.articles,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.getMagicArticlesByProviderFailure(message)),
          ),
        ),
      ),
    ),
  );

const checkIfProviderReachedDailyMagicContentLimitEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      magicContentActions.checkIfProviderReachedDailyMagicContentLimit.match,
    ),
    mergeMap(() =>
      from(
        MagicContentService.checkIfProviderReachedDailyMagicContentLimit(),
      ).pipe(
        mergeMap(res => [
          magicContentActions.checkIfProviderReachedDailyMagicContentLimitSuccess(
            res.data.message === 'You have reached the daily post limit',
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(
              magicContentActions.checkIfProviderReachedDailyMagicContentLimitFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const triggerDownloadUnsplashPhotoEventEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.triggerDownloadUnsplashPhotoEvent.match),
    mergeMap(({payload}) =>
      from(MagicContentService.triggerDownloadUnsplashPhotoEvent(payload)).pipe(
        mergeMap(() => [
          magicContentActions.triggerDownloadUnsplashPhotoEventSuccess(),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(
              magicContentActions.triggerDownloadUnsplashPhotoEventFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const magicContentEpics = [
  generateExpertSummaryEpic,
  generateExpertSummaryFailureEpic,
  generateMagicContentEpic,
  generateMagicContentFailureEpic,
  reGenerateMagicContent,
  reGenerateMagicContentFailureEpic,
  saveMagicArticleEpic,
  saveMagicArticleFailureEpic,
  getMagicImagesEpic,
  getMagicImagesFailureEpic,
  getMagicArticleBySlugEpic,
  getMagicArticleBySlugFailureEpic,
  getMagicArticlesByProviderEpic,
  checkIfProviderReachedDailyMagicContentLimitEpic,
  triggerDownloadUnsplashPhotoEventEpic,
].flatMap(epic => epic);
