import React from 'react';
import classNames from 'classnames';
import {
  CloseIcon,
  FormError,
  FormInput,
  FormLabel,
  FormTextArea,
} from 'components';
import {NoteFormProps} from 'definitions/Yup';
import {Medication, SliceStatus} from 'interfaces';
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrorsImpl,
  UseFormRegister,
} from 'react-hook-form';
import {useSelector} from 'react-redux';
import Select, {OnChangeValue} from 'react-select';
import styled from 'styled-components';

import {selectMedicationsStatus} from './notesSelectors';

type MedicationOptionType = {medicationName: string; medicationId: string};
export type NoteFormType = {
  chiefComplaint: string;
  patientNotes: string;
  plan: string;
  prescribeMedication: boolean;
  pharmacyService: string;
  medications: {
    medicationName: Medication[];
    medicationInstruction: string;
    quantity: number;
    daySupply: number;
    refillNumber: number;
    paymentType: string;
    cardHolderId: string;
    rxGroup: string;
    rxBin: string;
    pcn: string;
  }[];
  icdCodes: {
    value?: string;
    label?: string;
    _id?: string;
  }[];
  appointmentType: string;
  duration: number | null;
};

type Props = {
  fields: FieldArrayWithId<NoteFormProps, 'medications', 'id'>[];
  control: Control<NoteFormProps, any>;
  register: UseFormRegister<NoteFormProps>;
  errors: Partial<FieldErrorsImpl<NoteFormProps>>;
  remove: (index: number) => void;
  medicationList: MedicationOptionType[];
  autoSave: () => void;
};

const style = {
  control: (base: any) => ({
    ...base,
    border: '0 !important',
    boxShadow: '0 !important',
    background: 'transparent !important',
    '&:hover': {},
  }),
};
const Placeholder = styled.div<{error: string}>`
  color: ${props => (props.error ? '#f02848' : '#a0aebf')};
`;

function AddMedications({
  fields,
  control,
  register,
  errors,
  remove,
  medicationList,
  autoSave,
}: Props) {
  const MedicationsStatus = useSelector(selectMedicationsStatus);
  const isLoading = MedicationsStatus === SliceStatus.pending;

  return (
    <section>
      {fields.map(
        (
          {id, medicationInstruction, daySupply, refillNumber, quantity},
          i: number,
        ) => {
          return (
            <div
              key={id}
              className="mt-3 p-4 bg-gray-50 rounded-lg border border-gray-100"
            >
              <div className="flex justify-between items-center mb-4">
                <p className="font-bold text-base">Medication {i + 1}</p>
                <button
                  className="focus:outline-none"
                  onClick={() => remove(i)}
                >
                  <CloseIcon fillColor="#6b6b6b" strokeWidth={20} />
                </button>
              </div>

              <article className="pr-2 mb-5">
                <FormLabel
                  className="font-medium pb-1"
                  htmlFor={`medications[${i}].medicationName`}
                >
                  Medication Name
                </FormLabel>
                <Controller
                  name={`medications.${i}.medicationName`}
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <Select
                      onChange={(
                        val: OnChangeValue<MedicationOptionType, boolean>,
                      ) => {
                        onChange(val);
                        autoSave();
                      }}
                      value={value ? (value as MedicationOptionType[]) : []}
                      getOptionLabel={(options: MedicationOptionType) =>
                        options.medicationName
                      }
                      getOptionValue={(options: MedicationOptionType) =>
                        options.medicationId
                      }
                      isLoading={isLoading}
                      onBlur={onBlur}
                      isMulti
                      options={medicationList}
                      className={classNames(
                        'bg-white border appearance-none w-full leading-tight focus:outline-none rounded-lg',
                        {
                          'border-red-500':
                            errors.medications &&
                            (errors?.medications[i]?.medicationName as any)
                              ?.message,
                        },
                      )}
                      styles={style}
                      placeholder={
                        <Placeholder
                          error={
                            errors.medications &&
                            (errors?.medications[i]?.medicationName as any)
                              ?.message
                          }
                        >
                          Select Medications
                        </Placeholder>
                      }
                      noOptionsMessage={searchText =>
                        `No Medication found: ${searchText.inputValue}`
                      }
                      loadingMessage={() => 'Loading Medications...'}
                    />
                  )}
                />
                <FormError
                  error={
                    errors.medications
                      ? (errors?.medications[i]?.medicationName as any)?.message
                      : ''
                  }
                />
              </article>

              <article>
                <FormLabel
                  className="font-medium pb-1"
                  htmlFor={`medications[${i}].medicationInstruction`}
                >
                  Medication Instruction
                </FormLabel>
                <FormTextArea
                  id={`medications[${i}].medicationInstruction`}
                  name={`medications.${i}.medicationInstruction`}
                  register={register}
                  error={
                    errors.medications
                      ? errors?.medications[i]?.medicationInstruction?.message
                      : ''
                  }
                  className="rounded-lg"
                  placeholder="Medication Instruction"
                  defaultValue={medicationInstruction}
                  onChange={autoSave}
                />
                <FormError
                  error={
                    errors.medications
                      ? errors?.medications[i]?.medicationInstruction?.message
                      : ''
                  }
                />
              </article>

              <div className="flex justify-between items-center mt-4">
                <article className="w-5/12">
                  <FormLabel
                    className="font-medium pb-1"
                    htmlFor={`medications[${i}].quantity`}
                  >
                    Quantity
                  </FormLabel>
                  <FormInput
                    id={`medications[${i}].quantity`}
                    name={`medications.${i}.quantity`}
                    register={register}
                    error={
                      errors.medications &&
                      errors?.medications[i]?.quantity?.message
                    }
                    bgColor="white"
                    placeholder="Quantity"
                    defaultValue={quantity}
                    onChange={autoSave}
                  />
                  <FormError
                    error={
                      errors.medications &&
                      errors?.medications[i]?.quantity?.message
                    }
                  />
                </article>
                <article className="w-5/12">
                  <FormLabel
                    className="font-medium pb-1"
                    htmlFor={`medications[${i}].daySupply`}
                  >
                    Day Supply
                  </FormLabel>
                  <FormInput
                    id={`medications[${i}].daySupply`}
                    name={`medications.${i}.daySupply`}
                    register={register}
                    error={
                      errors.medications &&
                      errors?.medications[i]?.daySupply?.message
                    }
                    bgColor="white"
                    placeholder="Day Supply"
                    defaultValue={daySupply}
                    onChange={autoSave}
                  />
                  <FormError
                    error={
                      errors.medications &&
                      errors?.medications[i]?.daySupply?.message
                    }
                  />
                </article>
              </div>

              <div className="mt-4">
                <FormLabel
                  className="font-medium pb-1"
                  htmlFor={`medications[${i}].refillNumber`}
                >
                  Refill
                </FormLabel>
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4, 5].map(v => (
                    <article key={v} className="flex items-center mr-3">
                      <input
                        key={v}
                        type="radio"
                        value={v}
                        id={`medications[${i}].refillNumber`}
                        {...register(`medications.${i}.refillNumber`)}
                        defaultChecked={refillNumber === v}
                        className="form-radio text-blue-600 transition duration-150 ease-in-out"
                        onChange={autoSave}
                      />
                      <label
                        htmlFor={`medications[${i}].refillNumber`}
                        className="pl-1 block text-xs text-gray-700 text-left"
                      >
                        {v}
                      </label>
                    </article>
                  ))}
                </div>
              </div>
            </div>
          );
        },
      )}
    </section>
  );
}
export default AddMedications;
