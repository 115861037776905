import {useCallback, useEffect, useState} from 'react';
import {store} from 'app/store';
import {getAllCountries} from 'interfaces';
import Cookies from 'js-cookie';
import {
  getLocaleFromCountryCode,
  isSupportedLocale,
} from 'utils/translationUtils';

export const useLocale = (): {
  locale: string;
  setLocale: (locale: string) => void;
  localeOptions: {label: string; value: string}[];
} => {
  const initLocale = () => {
    const pathname = window.location.pathname;
    const pathComponents = pathname.split('/');
    const localeFromPath = pathComponents[1];
    const isFromWellniteComDomain = document?.referrer.includes('wellnite.com');
    if (
      localeFromPath &&
      isFromWellniteComDomain &&
      isSupportedLocale(localeFromPath)
    ) {
      return localeFromPath;
    }
    const cookieLocale = Cookies.get('preferredLocale');
    const countryOfResidence =
      store.getState()?.user?.current?.countryOfResidence;

    if (cookieLocale) {
      return cookieLocale;
    }
    if (countryOfResidence) {
      const countryLocale = getLocaleFromCountryCode(countryOfResidence.code);
      if (countryLocale) return countryLocale;
    }
    const browserLocale = navigator.language;
    if (browserLocale && isSupportedLocale(browserLocale))
      return browserLocale.toLowerCase();
    return 'en-us';
  };
  const currentLocale = initLocale();
  const [locale, setCurrentLocale] = useState<string>(currentLocale);

  // Handle changes in locale from change country button
  useEffect(() => {
    if (locale != currentLocale) {
      setCurrentLocale(currentLocale);
    }
  }, [currentLocale]);

  const setLocale = useCallback((newLocale: string) => {
    if (isSupportedLocale(newLocale)) {
      Cookies.set('preferredLocale', newLocale);
      setCurrentLocale(newLocale);
    }
  }, []);

  const localeOptions = getAllCountries().map(c => ({
    label: c.name,
    value: c.locale,
  }));

  return {locale, setLocale, localeOptions};
};
