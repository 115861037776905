import React from 'react';
import {useToggle} from 'hooks';
import {FieldValues} from 'react-hook-form';

import {PasswordHideIcon, PasswordShowIcon} from '../../SvgIcon';
import {AbstractFormItem} from '../Base/AbstractFormItem';
import {getTextInputClasses, TextInputProps} from '../Base/Models/TextInput';

export function FormPasswordInput<
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  id,
  placeholder,
  bgColor = 'white',
  inputClasses,
  ariaLabel,
  ...rest
}: TextInputProps<TFieldValues, TContext>) {
  const {toggleOn: visible, onToggleClick: onVisibilityIconClick} = useToggle();

  return (
    <AbstractFormItem
      id={id}
      {...rest}
      render={({field: {value, onChange}, fieldState: {error}}) => (
        <div className="relative">
          <div
            onClick={onVisibilityIconClick}
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          >
            {visible ? (
              <PasswordHideIcon strokeColor="text-gray-600" />
            ) : (
              <PasswordShowIcon strokeColor="text-gray-600" />
            )}
          </div>
          <input
            placeholder={placeholder}
            id={id}
            type={visible ? 'text' : 'password'}
            value={value}
            onChange={onChange}
            className={getTextInputClasses(error, inputClasses, bgColor)}
            aria-label={ariaLabel ?? id}
            data-testid={id}
          />
        </div>
      )}
    ></AbstractFormItem>
  );
}
