import React from 'react';

import {SvgIcon} from './SvgIcon';

export const IdFrontIcon: SvgIcon = ({width = 30, height = 22}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 4C0.75 2.20507 2.20507 0.75 4 0.75H26C27.7949 0.75 29.25 2.20507 29.25 4V18C29.25 19.7949 27.7949 21.25 26 21.25H4C2.20507 21.25 0.75 19.7949 0.75 18V4Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M3.75 6C3.75 4.75736 4.75736 3.75 6 3.75H13C14.2426 3.75 15.25 4.75736 15.25 6V16C15.25 17.2426 14.2426 18.25 13 18.25H6C4.75736 18.25 3.75 17.2426 3.75 16V6Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <circle cx="9.5" cy="9.5" r="1.75" stroke="#ED82B3" strokeWidth="1.5" />
    <path
      d="M7.43412 13.5737C7.56436 12.5319 8.45002 11.75 9.5 11.75C10.55 11.75 11.4356 12.5319 11.5659 13.5737L12.1504 18.25H6.84959L7.43412 13.5737Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M18 6.75C18 6.33579 18.3358 6 18.75 6H26.25C26.6642 6 27 6.33579 27 6.75C27 7.16421 26.6642 7.5 26.25 7.5H18.75C18.3358 7.5 18 7.16421 18 6.75Z"
      fill="#ED82B3"
    />
    <path
      d="M18 9.75C18 9.33579 18.3358 9 18.75 9H26.25C26.6642 9 27 9.33579 27 9.75C27 10.1642 26.6642 10.5 26.25 10.5H18.75C18.3358 10.5 18 10.1642 18 9.75Z"
      fill="#ED82B3"
    />
    <path
      d="M18 12.75C18 12.3358 18.3358 12 18.75 12H23.25C23.6642 12 24 12.3358 24 12.75C24 13.1642 23.6642 13.5 23.25 13.5H18.75C18.3358 13.5 18 13.1642 18 12.75Z"
      fill="#ED82B3"
    />
  </svg>
);

export const IdBackIcon: SvgIcon = ({width = 30, height = 22}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 4C0.75 2.20507 2.20507 0.75 4 0.75H26C27.7949 0.75 29.25 2.20507 29.25 4V18C29.25 19.7949 27.7949 21.25 26 21.25H4C2.20507 21.25 0.75 19.7949 0.75 18V4Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M3 5.75C3 5.33579 3.33579 5 3.75 5H26.25C26.6642 5 27 5.33579 27 5.75C27 6.16421 26.6642 6.5 26.25 6.5H3.75C3.33579 6.5 3 6.16421 3 5.75Z"
      fill="#ED82B3"
    />
  </svg>
);

export const PassportIcon: SvgIcon = ({width = 19, height = 29}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 4C0.75 2.20508 2.20507 0.75 4 0.75H15C16.7949 0.75 18.25 2.20507 18.25 4V25C18.25 26.7949 16.7949 28.25 15 28.25H4C2.20507 28.25 0.75 26.7949 0.75 25V4Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M3.75 6C3.75 4.75736 4.75736 3.75 6 3.75H13C14.2426 3.75 15.25 4.75736 15.25 6V16C15.25 17.2426 14.2426 18.25 13 18.25H6C4.75736 18.25 3.75 17.2426 3.75 16V6Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <circle cx="9.5" cy="9.5" r="1.75" stroke="#ED82B3" strokeWidth="1.5" />
    <path
      d="M7.43412 13.5737C7.56436 12.5319 8.45002 11.75 9.5 11.75C10.55 11.75 11.4356 12.5319 11.5659 13.5737L12.1504 18.25H6.84959L7.43412 13.5737Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M5 20.75C5 20.3358 5.33579 20 5.75 20H13.25C13.6642 20 14 20.3358 14 20.75C14 21.1642 13.6642 21.5 13.25 21.5H5.75C5.33579 21.5 5 21.1642 5 20.75Z"
      fill="#ED82B3"
    />
    <path
      d="M5 23.75C5 23.3358 5.33579 23 5.75 23H13.25C13.6642 23 14 23.3358 14 23.75C14 24.1642 13.6642 24.5 13.25 24.5H5.75C5.33579 24.5 5 24.1642 5 23.75Z"
      fill="#ED82B3"
    />
  </svg>
);
