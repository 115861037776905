import {userActions} from 'features/User/userSlice';
import {
  SliceStatus,
  TNotification,
  UpdateNotificationFormData,
} from 'interfaces';

import {
  ActionReducerMapBuilder,
  createAction,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

const openNotificationState = createAction<{
  notificationId: string;
  entityId: string;
  changes: {state: 'read'};
}>('openNotificationState');
const openNotificationStateFailure = createAction(
  'openNotificationStateFailure',
);

const updateShareRequestNotification = createAction<
  {
    notificationId: string;
    entityId: string;
    postShared?: string;
    bookmarkType?: string;
    referencePath?: string;
  } & UpdateNotificationFormData
>('updateShareRequestNotification');
const updateShareRequestNotificationFailure = createAction(
  'updateShareRequestNotificationFailure',
);

const updateShareResponseNotification = createAction<{
  notificationId: string;
  entityId: string;
  caption?: string;
  postId: string;
}>('updateShareResponseNotification');

const deleteNotification = createAction<{
  notificationId: string;
  entityId: string;
}>('deleteNotification');
const deleteNotificationFailure = createAction('deleteNotificationFailure');
const deleteReceivedNotification = createAction<string>(
  'deleteReceivedNotification',
);
export const getNotifications = createAction<{page: number; limit: number}>(
  'getNotifications',
);
export const getNotificationsSuccess = createAction('getNotificationsSuccess');
export const getNotificationsFailure = createAction('getNotificationsFailure');

export const notificationsAdapter = createEntityAdapter<TNotification>({
  selectId: (value): string => {
    return value._id;
  },
  sortComparer: (a, b) => b._id.localeCompare(a._id),
});

const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationsAdapter.getInitialState({
    error: '',
    status: SliceStatus.idle,
    unreadCount: 0,
  }),
  reducers: {
    notificationUpdated(
      state,
      action: PayloadAction<{
        id: string;
        changes: Record<string, string | Record<string, string>>;
      }>,
    ) {
      if ('data' in action.payload.changes) {
        const {
          changes: {data, ...rest},
          id,
        } = action.payload;
        const changes = {
          ...rest,
          notification: {
            text: state.entities[id]?.notification.text || '',
            data: {
              ...state.entities[id]?.notification.data,
              ...(data as Record<string, string>),
            },
          },
        };
        notificationsAdapter.updateOne(state, {id, changes});
      } else {
        notificationsAdapter.updateOne(state, action.payload);
      }

      if (action.payload.changes?.state === 'read') {
        state.unreadCount -= 1;
      }
    },
    notificationDeleted(state, action: PayloadAction<string>) {
      if (state.entities[action.payload]?.state === 'unread') {
        state.unreadCount -= 1;
      }
      notificationsAdapter.removeOne(state, action.payload);
    },
    notificationFailedToDelete(
      state,
      action: PayloadAction<TNotification | undefined>,
    ) {
      if (action.payload) {
        notificationsAdapter.addOne(state, action.payload);
      }
    },
    notificationReceived(state, action: PayloadAction<TNotification>) {
      notificationsAdapter.addOne(state, action.payload);
      state.unreadCount += 1;
    },
    notificationsReceived(
      state,
      action: PayloadAction<{
        notifications: TNotification[];
        unreadCount?: number;
        page: number;
      }>,
    ) {
      notificationsAdapter.upsertMany(state, action.payload.notifications);
      state.unreadCount = action.payload.unreadCount ?? state.unreadCount;
    },
  },
  extraReducers: (
    builder: ActionReducerMapBuilder<
      EntityState<TNotification> & {
        error: string;
        status: SliceStatus;
        unreadCount: number;
      }
    >,
  ) => {
    builder
      .addCase(getNotifications, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(getNotificationsSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(getNotificationsFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(
        userActions.stateLoadingDone,
        (_state, action) => action.payload.state.notification,
      )
      .addCase(userActions.setAsyncError, (state, action) => {
        state.error =
          action.payload.filter === 'notification'
            ? action.payload.message
            : state.error;
      })
      .addCase(userActions.resetAsyncError, (state, action) => {
        state.error = action.payload === 'notification' ? '' : state.error;
      })
      .addDefaultCase(state => state);
  },
});

export const {
  reducer: notificationReducer,
  name: notificationReducerName,
  actions: {
    notificationUpdated,
    notificationsReceived,
    notificationDeleted,
    notificationFailedToDelete,
    notificationReceived,
  },
} = notificationSlice;

export type TNotificationActions =
  | ReturnType<typeof openNotificationState>
  | ReturnType<typeof openNotificationStateFailure>
  | ReturnType<typeof deleteNotification>
  | ReturnType<typeof deleteNotificationFailure>
  | ReturnType<typeof deleteReceivedNotification>
  | ReturnType<typeof updateShareRequestNotification>
  | ReturnType<typeof updateShareRequestNotificationFailure>
  | ReturnType<typeof updateShareResponseNotification>
  | ReturnType<typeof notificationUpdated>
  | ReturnType<typeof notificationDeleted>
  | ReturnType<typeof notificationFailedToDelete>
  | ReturnType<typeof notificationReceived>
  | ReturnType<typeof notificationsReceived>
  | ReturnType<typeof getNotifications>
  | ReturnType<typeof getNotificationsSuccess>
  | ReturnType<typeof getNotificationsFailure>;

export const notificationActions = {
  openNotificationState,
  openNotificationStateFailure,
  deleteNotification,
  deleteNotificationFailure,
  deleteReceivedNotification,
  updateShareRequestNotification,
  updateShareRequestNotificationFailure,
  updateShareResponseNotification,
  notificationUpdated,
  notificationReceived,
  notificationsReceived,
  notificationDeleted,
  notificationFailedToDelete,
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFailure,
};

export type NotificationState = ReturnType<typeof notificationReducer>;
