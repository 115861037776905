import React from 'react';

import {SvgIcon} from './SvgIcon';

export const Progress: SvgIcon = ({width = 20, height = 20}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 124 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M106.585 37.5377V15.8371C106.585 9.12962 101.456 4 94.7483 4L15.8371 4.00121C9.12962 4.00121 4 9.13084 4 15.8383V94.7495C4 101.457 9.12962 106.587 15.8371 106.587H41.4828"
      stroke="#D9D9D9"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.6191 39.5127H78.9659"
      stroke="#D9D9D9"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.6191 63.1865H55.2925"
      stroke="#D9D9D9"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M82.5172 112.106L67.1295 115.263C64.3673 115.658 61.6061 113.29 62.3944 110.528L65.5514 95.1404L101.456 59.2362C103.034 57.6583 105.401 57.6583 106.979 59.2362L118.816 71.0732C120.394 72.6512 120.394 75.0186 118.816 76.5966L82.5172 112.106Z"
      stroke="#D9D9D9"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FolderIcon: SvgIcon = ({width = 20, height = 20}) => (
  <>
    <svg
      width={width}
      height={height}
      className="text-blue hover:fill-blue hover:blue cursor-pointer"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="2.5 4.5 19 15"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.2}
        d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
      ></path>
    </svg>
  </>
);

export const RoundCheckIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <circle cx="10" cy="10" r="9.25" stroke="#1D8E13" strokeWidth="1.5" />
    <path d="M6.5 10L9 12.5L14 7.5" stroke="#1D8E13" strokeWidth="1.5" />
  </svg>
);
