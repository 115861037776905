import React, {FC} from 'react';
import {FormError, FormLabel, FormTextArea} from 'components';
import {FormSelect} from 'components/Basic/Form/V2';
import {ProviderCancelAppointmentSchema} from 'definitions/Yup';
import {ProviderCancelAppointmentFormData} from 'interfaces';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {rsModalInputStyles} from 'styles';

import {yupResolver} from '@hookform/resolvers/yup';

import {cancelAppointmentOptions} from '../cancelAppointmentOptions';

type Props = {
  onSubmit: (formdata: ProviderCancelAppointmentFormData) => void;
  store: {
    reasonForCancellation: string;
    otherReasonForCancellation: string;
  };
  formId: string;
};
const ReasonForCancellationForm: FC<Props> = ({formId, onSubmit, store}) => {
  const {t} = useTranslation();
  const {
    handleSubmit,
    formState: {errors},
    register,
    control,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: store,
    resolver: yupResolver(ProviderCancelAppointmentSchema),
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <article className="mb-5 text-gray-700">
        <FormSelect
          control={control}
          id="reasonForCancellation"
          name="reasonForCancellation"
          options={cancelAppointmentOptions(t)}
          extractValue={true}
          labelClasses="!text-sm font-normal"
          styles={rsModalInputStyles}
          menuPortalTarget={document.body}
          label={t(
            'cancel_appointment.reasonInputLabel',
            'Please add a reason for your cancellation',
          )}
        />
      </article>

      {watch('reasonForCancellation') === 'other' ? (
        <article className="px-2 mb-5 text-gray-700">
          <FormLabel className="!text-sm" htmlFor="otherReasonForCancellation">
            {t(
              'cancel_appointment.otherReasonLabel',
              'Please Specify Other Cancellation Reason',
            )}
          </FormLabel>
          <FormTextArea
            id="otherReasonForCancellation"
            name="otherReasonForCancellation"
            register={register}
            error={errors.otherReasonForCancellation}
            className="rounded-md border-gray-400"
            placeholder={t(
              'cancel_appointment.otherReasonPlaceholderText',
              'Other Cancellation Reason',
            )}
          />
          <FormError error={errors.otherReasonForCancellation?.message} />
        </article>
      ) : null}
    </form>
  );
};
export default ReasonForCancellationForm;
