import * as React from 'react';
import {PropsWithChildren, ReactNode} from 'react';
import classNames from 'classnames';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';

import {FormControlledComponent} from './Models/FormComponent';
import {FormError} from './FormError';

export type AbstractFormItemProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = PropsWithChildren<FormControlledComponent<TFieldValues, TContext>> & {
  id: string;
  label?: ReactNode;
  subLabel?: ReactNode;
  name?: FieldPath<TFieldValues>;
  classes?: string;
  labelClasses?: string;
  errorClasses?: string;
  isRequired?: boolean;
  showLabelAfterInput?: boolean;
  inlineLabelAndInput?: boolean;
  defaultValue?: FieldPathValue<TFieldValues, FieldPath<TFieldValues>>;
};

type FormItemProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = AbstractFormItemProps<TFieldValues, TContext> & {
  render: ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps<TFieldValues, FieldPath<TFieldValues>>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<TFieldValues>;
  }) => React.ReactElement;
};

/**
 * This abstract component should not be used directly.
 * Other forms components that are intended to be used with react-hook-form must extend this component.
 */
export const AbstractFormItem = <
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  id,
  name = id as FieldPath<TFieldValues>,
  label,
  subLabel,
  classes,
  labelClasses,
  errorClasses,
  isRequired,
  defaultValue,
  showLabelAfterInput = false,
  inlineLabelAndInput = false,
  control,
  render,
}: FormItemProps<TFieldValues, TContext>) => {
  const renderLabel = () => {
    if (!label) return null;
    else if (typeof label === 'string') {
      return (
        <label
          className={classNames(
            `${labelClasses} block text-left leading-snug font-medium mb-2`,
            {
              "after:content-['*'] after:ml-0.5 after:text-red-500": isRequired,
            },
          )}
          id={id}
          htmlFor={name}
        >
          {label}
        </label>
      );
    } else {
      return label;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue!}
      render={props => (
        <>
          <article
            className={classNames(`pt-6 ${classes}`, {
              'flex items-start md:items-center': inlineLabelAndInput,
            })}
          >
            {!showLabelAfterInput && renderLabel()}
            {subLabel ? (
              <span className="pb-3 text-xs text-darkGray font-medium">
                {subLabel}
              </span>
            ) : null}
            <section>
              {render(props)}
              {!inlineLabelAndInput && (
                <FormError
                  error={props.fieldState.error}
                  id={`${id}-error`}
                  errorClasses={errorClasses}
                />
              )}
            </section>
            {showLabelAfterInput && renderLabel()}
          </article>
          {inlineLabelAndInput && (
            <FormError
              error={props.fieldState.error}
              id={`${id}-error`}
              errorClasses={errorClasses}
            />
          )}
        </>
      )}
    ></Controller>
  );
};
