import React, {FC, lazy, useCallback, useState} from 'react';
import classNames from 'classnames';
import {
  AccountIcon,
  AvailabilityIconV2,
  Button,
  LoadingSpinner,
} from 'components';
import {CircleCheckIcon, WarningTriangle} from 'components/Basic/SvgIcon';
import dayjs from 'dayjs';
import ChargeModal from 'features/Appointment/Charge/ChargeModal';
import {ChargeStatus, PaypalStandardPaymentCountries} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {
  componentLoader,
  getCountryDetailsByIsoCode,
  isDigitalPracticeOutOfPocketMember,
  isMember,
  isPaypalStandardPaymentCountries,
  isProvider,
  isTherapist,
  showPayLaterOptions,
  titleCaseName,
} from 'utils';

import {selectDueAppointments} from '../appointmentSelectors';
import PaymentRequestModal from '../Charge/PaymentRequestModal';
import SendPaymentRequestModal from '../SendPaymentRequest';

import {CalendarItemProps, currencies, labelType} from './CalendarItemProps';

import 'react-day-picker/lib/style.css';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

export const CalendarItemCardV2: FC<CalendarItemProps> = ({
  startTime,
  duration,
  patientFirstName,
  patientId,
  user,
  patientLastName,
  calendar,
  appointmentID,
  patientDetails,
  videoLink,
  canceled,
  participants,
  notes,
  localTime,
  isPastEvent,
  ...props
}) => {
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [showSendPaymentRequestModal, setShowSendPaymentRequestModal] =
    useState(false);
  const [onSuccessModal, setOnSuccessModal] = useState(false);
  const [skip, setSkip] = useState(false);
  const {t} = useTranslation();
  const isObsoleteEvent = isPastEvent?.(localTime);
  const providerCountryDetails = getCountryDetailsByIsoCode(
    user!.countryOfResidence.code,
  );
  //covers getting currency icon for both set price and charge views
  const currencySymbol = providerCountryDetails?.currencySymbol;
  const dueAppointments: number[] = useSelector(selectDueAppointments);

  const isOOPAppointment =
    isTherapist(user) && isDigitalPracticeOutOfPocketMember(patientDetails);

  const isAppointmentChargeFailed =
    props?.chargeStatus &&
    [
      ChargeStatus.notCharged,
      ChargeStatus.clientFailure,
      ChargeStatus.systemFailure,
    ].includes(props.chargeStatus);

  /**
   * This code snippet defines a memoized function called `dpOOPPricing` that returns a JSX element based on the appointment's price and charge status.

   - If the appointment is not an out-of-pocket (OOP) appointment, it returns `null`.
   - If the appointment is OOP and has no price set, it returns a button to set the price.
   - If the appointment is OOP, has a price set, and its charge status is scheduled, it returns a text indicating that the charge is scheduled.
   - If the appointment is OOP, has a price set, and its charge failed, it returns a button to charge again.
   - If the appointment is OOP, has a price set, and its charge is successful, it returns a text indicating that the charge was successful.
   - If the appointment is OOP, has a price set, and its charge status is neither scheduled, failed, nor successful, it returns a button to charge.

   The function uses conditional statements to determine which JSX element to return based on the appointment's properties.
   */
  const dpOOPPricing = useCallback(() => {
    if (!isOOPAppointment) return null;

    // set the dp-oop appointment price
    if (!props?.price) {
      return (
        <Button
          type="button"
          borderRadius="full"
          borderColor="transparent"
          fontSize="sm"
          className="h-10 px-3 flex justify-center items-center whitespace-nowrap"
          data-testid="charge"
          onClick={props.onShowChargeModal}
        >
          {currencySymbol ? currencySymbol : null}
          &nbsp;{t('setPrice')}
        </Button>
      );
    }
    // charge the dp-oop appointment
    if (props.price && !props?.chargeStatus) {
      return (
        <Button
          type="button"
          borderRadius="full"
          btnType="custom"
          borderColor="lime-600"
          bgColor="white"
          fontSize="sm"
          className="h-10 px-3 bg-[#FF8DC1] hover:bg-white hover:text-[#FF8DC1] flex justify-center items-center whitespace-nowrap text-white border-[#FF8DC1]"
          onClick={props.onShowChargeModal}
          data-testid="set-price"
        >
          {currencySymbol ? currencySymbol : null}
          &nbsp;{t('charge')}
        </Button>
      );
    }
    if (props.chargeStatus === ChargeStatus.scheduled) {
      return (
        <div className="w-auto">
          <span className="flex justify-center items-center gap-1 max-w-max bg-[#ED82B3]/10 text-[#ED82B3] rounded-full px-3 h-10">
            {t('chargeAppointments.scheduled')}&nbsp;
            <AvailabilityIconV2
              height={16}
              width={16}
              strokeColor="[#ED82B3]"
            />
          </span>
        </div>
      );
    }
    if (props.chargeStatus === ChargeStatus.charged) {
      return (
        <div
          className={classNames('w-full', {
            'flex items-center justify-center': isObsoleteEvent,
            'mt-3': !isObsoleteEvent,
          })}
        >
          <span className="flex justify-center items-center gap-1 max-w-max bg-[#2E62EC1A] text-[#2E62EC] rounded-full px-3 h-10">
            {t('chargeAppointments.successfullyCharged')}
            &nbsp;
            <CircleCheckIcon height={20} width={20} />
          </span>
        </div>
      );
    }
    if (isAppointmentChargeFailed) {
      return (
        <Button
          type="button"
          borderRadius="full"
          btnType="custom"
          borderColor="lime-600"
          bgColor="white"
          fontSize="sm"
          className="h-10 px-3 bg-[#FF8DC1] hover:bg-white hover:text-[#FF8DC1] flex justify-center items-center whitespace-nowrap text-white border-[#FF8DC1]"
          onClick={props.onShowChargeModal}
          data-testid="set-price"
        >
          {currencySymbol ? currencySymbol : null}
          &nbsp;{t('chargeAgain')}
        </Button>
      );
    }
    return null;
  }, [
    props,
    isOOPAppointment,
    isAppointmentChargeFailed,
    isObsoleteEvent,
    currencySymbol,
    t,
  ]);

  //
  const dpOOPStdCheckoutPricing = useCallback(() => {
    if (!isOOPAppointment) return null;

    const showPaymentRequestBtn = !props?.price || !props?.chargeStatus;
    // Reminder for the dp-oop session of the standard checkout listed providers
    if (showPaymentRequestBtn) {
      return (
        <Button
          type="button"
          borderRadius="full"
          borderColor="[#FF8DC1]"
          bgColor="[#FF8DC1]"
          fontSize="sm"
          btnType="custom"
          className="h-10 px-3 border hover:bg-white hover:text-[#FF8DC1] flex justify-center items-center whitespace-nowrap"
          data-testid="charge"
          onClick={() => setShowSendPaymentRequestModal(true)}
        >
          {t('paymentRequest.paymentReq')}
        </Button>
      );
    }

    if (props?.chargeStatus === ChargeStatus.charged) {
      return (
        <div
          className={classNames('w-full', {
            'flex items-center justify-center': isObsoleteEvent,
            'mt-3': !isObsoleteEvent,
          })}
        >
          <span className="flex justify-center items-center gap-1 max-w-max bg-[#2E62EC1A] text-[#2E62EC] font-normal rounded-full px-3 py-2 text-sm">
            {t('chargeAppointments.successfullyCharged')}
            &nbsp;
            <CircleCheckIcon height={17} width={17} />
          </span>
        </div>
      );
    }
    return null;
  }, [props, isOOPAppointment, t]);

  return (
    <section
      data-testid="calendar-item"
      className="mb-4 rounded-2xl border lg:max-w-[370px] text-sm font-inter p-3 bg-white"
    >
      {isProvider(user) && !patientId ? (
        <p className="text-red-600/90 mb-3 bg-red-600/10 rounded-md leading-5 py-1 px-3">
          {t('patientAccountRegistrationPending')}
        </p>
      ) : null}

      <div className="font-medium flex flex-row justify-between items-start gap-2">
        <div className="text-gray-700 text-left flex-grow leading-snug w-full overflow-hidden">
          <div className="leading-snug text-base font-normal truncate overflow-hidden mr-5">
            {!isMember(user)
              ? props.isGroupCall
                ? t('groupSession')
                : t('talkToPatient')
              : props.category}
          </div>
          <p className="text-sm font-medium float-left whitespace-nowrap">
            {startTime} &bull;&nbsp;
          </p>
          {/* <p>{endTime}</p> */}
          {props.isGroupCall && isMember(user) ? (
            <>
              <p className="pb-1 cursor-pointer">{calendar}</p>
              <p className="font-light">{notes}</p>
            </>
          ) : props.isGroupCall && isTherapist(user) ? (
            <>
              <p
                className="pb-1 cursor-pointer"
                role="button"
                onClick={() => setShowParticipantsModal(true)}
              >
                <span className="text-blue-600 hover:underline">
                  {participants?.length || 0} {t('participants')}
                </span>
              </p>
              <p className="font-light">{notes}</p>
            </>
          ) : (
            <p className="text-sm font-semibold overflow-hidden truncate pr-5">
              {titleCaseName(
                isProvider(user)
                  ? `${patientFirstName} ${patientLastName}`
                  : calendar || '',
              )}
            </p>
          )}
        </div>

        {isProvider(user) || isMember(user) ? (
          <div className="text-left">
            <p className="py-2 rounded-full whitespace-nowrap border text-sm text-primary/80 bg-primary/10 gray-700 px-3 flex items-center">
              <AvailabilityIconV2 height={20} strokeColor="#2E62EC" />
              &nbsp; {duration} {t('minutes')}
            </p>
          </div>
        ) : null}
      </div>

      {isOOPAppointment && isAppointmentChargeFailed ? (
        <div className="w-full flex mt-4 space-x-2">
          <WarningTriangle classes="w-4 h-4" />
          <p className="text-red-500 text-sm font-inter leading-4 font-normal">
            {props.meeting?.chargeError?.message || 'Failed to charge'}
          </p>
        </div>
      ) : null}

      {isOOPAppointment &&
      providerCountryDetails?.isoCode &&
      isPaypalStandardPaymentCountries(
        providerCountryDetails.isoCode as PaypalStandardPaymentCountries,
      ) &&
      props?.chargeStatus !== ChargeStatus.charged &&
      props?.meeting?.paymentRequestedAt ? (
        <div className="w-full mt-3 space-y-1">
          <p className="text-red-600 text-xs font-light">
            {t('chargeAppointments.paymentPending.title')}
          </p>
          <p className="text-xs text-gray-600 font-light">
            {t('chargeAppointments.paymentPending.description', {
              paymentRequestedAt: dayjs(
                props?.meeting?.paymentRequestedAt,
              ).format(`MMM D, YYYY [${t('at')}] h:mm A`),
            })}
          </p>
        </div>
      ) : null}

      <div className="font-medium text-gray-700 leading-snug w-full flex text-sm flex-row items-start pt-4 gap-2">
        {props.shouldShowCancelButton && !canceled && !isObsoleteEvent ? (
          <Button
            onClick={props.handleCancel}
            btnType="custom"
            textColor="gray-700"
            borderRadius="full"
            borderColor="gray-300"
            bgColor="white"
            className="h-10 text-sm flex items-center justify-center px-4 bg-white hover:bg-primary hover:text-white"
          >
            <span>{t('cancel')}</span>
          </Button>
        ) : null}

        <section
          className={classNames('flex flex-wrap w-full items-start gap-2', {
            'justify-between': props.chargeStatus === ChargeStatus.scheduled,
            'justify-center':
              isObsoleteEvent && props.chargeStatus === ChargeStatus.charged,
          })}
        >
          {isProvider(user) && !patientId ? null : canceled &&
            isMember(user) ? (
            <p className="text-red-500 font-bold ">{t('canceled')}</p>
          ) : (
            <>
              {props.isGroupCall && isMember(user) ? (
                <Button
                  type="button"
                  borderRadius="full"
                  borderColor="transparent"
                  fontSize="sm"
                  className="w-auto min-w-[100px] max-w-max px-3 h-10 flex justify-center items-center"
                  onClick={props.updateCallInterest}
                  disabled={
                    props.isJoinedInGroupCall || props.loadingState.interest
                  }
                >
                  {props.loadingState.interest ? (
                    <LoadingSpinner
                      color="#999"
                      type="Oval"
                      width={12}
                      height={12}
                    />
                  ) : props.isInterestedInGroupCall ? (
                    t('notInterested')
                  ) : (
                    t('interested')
                  )}
                </Button>
              ) : null}

              {!isObsoleteEvent ? (
                <Button
                  type="button"
                  borderRadius="full"
                  borderColor="transparent"
                  fontSize="sm"
                  className="h-10 text-sm px-3 flex justify-center items-center whitespace-nowrap"
                  data-testid="join"
                  disabled={
                    props.isGroupCallNotAllowed || props.loadingState.joining
                  }
                  onClick={props.onJoinClick}
                  tooltipContent={
                    props.isGroupCallNotAllowed ? t('submitInterest') : ''
                  }
                >
                  {props.loadingState.joining ? (
                    <LoadingSpinner
                      color="#999"
                      type="Oval"
                      width={12}
                      height={12}
                    />
                  ) : (
                    t('joinCall')
                  )}
                </Button>
              ) : null}

              {/* handle dp-oop pricing */}
              {providerCountryDetails?.isoCode &&
              isPaypalStandardPaymentCountries(
                providerCountryDetails.isoCode as PaypalStandardPaymentCountries,
              )
                ? dpOOPStdCheckoutPricing()
                : dpOOPPricing()}
            </>
          )}
        </section>

        {/* modals  */}
        <ChargeModal
          user={user}
          currencies={currencies}
          price={Number(props.price)}
          appointmentChargeCurrency={props.appointmentChargeCurrency}
          showChargeModal={props.showChargeModal}
          setShowChargeModal={props.setShowChargeModal}
          appointmentID={appointmentID.toString()}
          onChargeClick={props.onChargeClick}
          charged={props.chargeStatus}
          isLoading={props.isLoading}
          setSkip={setSkip}
          skip={skip}
          setOnCallPricingSkip={props.setOnCallPricingSkip}
          setShowSuccessModal={props.setShowSuccessModal}
          showSuccessModal={props.showSuccessModal}
          patientId={patientId || ''}
          localTime={localTime}
        />
        {/* international provider send payment request to the patient (South Africa and Columbia) */}
        <SendPaymentRequestModal
          user={user}
          patientId={patientId || ''}
          patientName={`${patientFirstName} ${patientLastName}`}
          // this should be `USD` because we're using PayPal express checkout
          currency="USD"
          appointmentID={appointmentID.toString()}
          price={Number(props.price)}
          showRequestModal={showSendPaymentRequestModal}
          setShowRequestModal={setShowSendPaymentRequestModal}
          isLoading={props.isLoading}
          onSuccessModal={onSuccessModal}
          setOnSuccessModal={setOnSuccessModal}
          startDate={dayjs(localTime).toISOString()}
        />

        {/* payment request modal for international patient (South Africa and Columbia) */}
        <PaymentRequestModal
          user={user}
          currencies={currencies}
          price={Number(props.price)}
          showPaymentRequestModal={props.showPaymentRequestModal}
          setShowPaymentRequestModal={props.setShowPaymentRequestModal}
          appointmentID={appointmentID.toString()}
          videoLink={videoLink}
          duration={duration}
          charged={props.chargeStatus}
          isLoading={props.isLoading}
          setShowSuccessModal={props.setShowSuccessPaymentModal}
          showSuccessModal={props.showSuccessPaymentModal}
          isPaymentDue={
            dueAppointments?.length > 0 &&
            dueAppointments.includes(appointmentID)
          }
          patientId={patientId || ''}
          localTime={localTime}
          {...(localTime && duration && props.meeting?.appointmentCharge
            ? {
                showPayLater: showPayLaterOptions(
                  localTime.toISOString(),
                  duration!,
                ),
              }
            : {})}
        />

        {props.isGroupCall &&
        isTherapist(user) &&
        Array.isArray(participants) ? (
          <Modal
            title={t(
              'group_appointment_participants',
              'Group Appointment Participants',
            )}
            isOpen={showParticipantsModal}
            buttonFn={() => setShowParticipantsModal(false)}
            messageType="none"
            showCloseIcon
            backdropClasses="bg-black/30"
          >
            {participants.map((participant, idx) => {
              const labelName = participant.labels?.[0]?.name;
              const labelTypeObject = labelType(t);
              const element = labelName
                ? labelTypeObject[labelName as keyof typeof labelTypeObject]
                : null;

              return (
                <div key={+idx} className="w-full flex justify-between py-4">
                  <div className="flex items-center space-x-3">
                    <AccountIcon />
                    <p className="text-lg">
                      {participant.firstName} {participant.lastName}
                    </p>
                  </div>
                  {participant.labels === null ? (
                    <p className="text-gray-600">{t('N/A')}</p>
                  ) : element ? (
                    element
                  ) : null}
                </div>
              );
            })}
          </Modal>
        ) : null}

        {props.showApptError.show ? (
          <Modal
            title="time warning"
            messageType="warning"
            isOpen={props.showApptError.show}
            buttonFn={props.onCloseApptTooEarlyModal}
            showCloseIcon
            backdropClasses="bg-black/30"
          >
            <div className="flex flex-col items-center justify-center space-y-10 max-w-lg mx-auto mb-8">
              <div
                className="px-5 text-center text-xl"
                dangerouslySetInnerHTML={{
                  __html: props.showApptError.message,
                }}
              />
              <Button
                btnType="primary"
                borderRadius="full"
                className="w-full max-w-[250px] mx-auto h-10 leading-snug"
                onClick={props.onCloseApptTooEarlyModal}
              >
                {t('CLOSE')}
              </Button>
            </div>
          </Modal>
        ) : null}
      </div>
    </section>
  );
};
