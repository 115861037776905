export type Country = {
  name: string;
  isoCode: string;
  phoneCode: string;
  currency: string;
  currencySymbol: string;
  latitude: string;
  longitude: string;
  language: string;
  languageCode: string;
  locale: string;
};

export type CountryOfResidenceType = {
  code: string;
  name: string;
};

const countries: Country[] = [
  {
    name: 'Australia',
    isoCode: 'AU',
    phoneCode: '61',
    currency: 'AUD',
    currencySymbol: '$',
    latitude: '-27.00000000',
    longitude: '133.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-au',
  },
  // {
  //   name: 'Brazil',
  //   isoCode: 'BR',
  //   phoneCode: '55',
  //   currency: 'BRL',
  //   currencySymbol: 'R$',
  //   latitude: '-10.00000000',
  //   longitude: '-55.00000000',
  //   language: 'Portuguese',
  //   languageCode: 'pt',
  //   locale: 'pt-br',
  // },
  {
    name: 'Canada',
    isoCode: 'CA',
    phoneCode: '1',
    currency: 'CAD',
    currencySymbol: '$',
    latitude: '60.00000000',
    longitude: '-95.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-ca',
  },
  {
    name: 'Chile',
    isoCode: 'CL',
    phoneCode: '56',
    currency: 'CLP',
    currencySymbol: '$',
    latitude: '-30.00000000',
    longitude: '-71.00000000',
    language: 'Spanish',
    languageCode: 'es',
    locale: 'es-cl',
  },
  {
    name: 'Colombia',
    isoCode: 'CO',
    phoneCode: '57',
    currency: 'COP',
    currencySymbol: '$',
    latitude: '4.00000000',
    longitude: '-72.00000000',
    language: 'Spanish',
    languageCode: 'es',
    locale: 'es-co',
  },
  {
    name: 'Mexico',
    isoCode: 'MX',
    phoneCode: '52',
    currency: 'MXN',
    currencySymbol: '$',
    latitude: '23.00000000',
    longitude: '-102.00000000',
    language: 'Spanish',
    languageCode: 'es',
    locale: 'es-mx',
  },
  {
    name: 'New Zealand',
    isoCode: 'NZ',
    phoneCode: '64',
    currency: 'NZD',
    currencySymbol: '$',
    latitude: '-41.00000000',
    longitude: '174.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-nz',
  },
  {
    name: 'South Africa',
    isoCode: 'ZA',
    phoneCode: '27',
    currency: 'ZAR',
    currencySymbol: '$',
    latitude: '-29.00000000',
    longitude: '24.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-za',
  },
  {
    name: 'Spain',
    isoCode: 'ES',
    phoneCode: '34',
    currency: 'EUR',
    currencySymbol: '€',
    latitude: '40.00000000',
    longitude: '-4.00000000',
    language: 'Spanish',
    languageCode: 'es',
    locale: 'es-es',
  },
  {
    name: 'United States',
    isoCode: 'US',
    phoneCode: '1',
    currency: 'USD',
    currencySymbol: '$',
    latitude: '37.00000000',
    longitude: '-95.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-us',
  },
];

export const getAllCountries = () => countries;
export const getCountryByIsoCode = (isoCode: string) =>
  countries.find(country => country.isoCode === isoCode);
