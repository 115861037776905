import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {notificationsAdapter, NotificationState} from './notificationSlice';

function getNotification(state: RootState): NotificationState {
  return state.notification;
}

export const selectUnreadNotificationsCount = createSelector(
  getNotification,
  notification => notification.unreadCount,
);

export const {
  selectAll: selectAllNotifications,
  selectEntities: selectNotificationsEntities,
  selectById: selectNotificationById,
} = notificationsAdapter.getSelectors<RootState>(state => {
  return state.notification;
});
