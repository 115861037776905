import React, {Dispatch, FC, Fragment, useRef} from 'react';
import {CloseIcon} from 'components';
import {BookAppointment} from 'features/Appointment/Booking/BookAppointment/index3';
import {AppointmentTypes, MemberProfile} from 'interfaces';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Dialog, Transition} from '@headlessui/react';

const DrawerContainer = styled.div`
  height: calc(100% - 160px);
`;

export const PopupDrawer: FC<{
  appointmentType: AppointmentTypes;
  member: MemberProfile;
  setShowBookingModal: Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  onClose: () => void;
  ignoreOutsideClick?: boolean;
}> = ({
  appointmentType,
  member,
  setShowBookingModal,
  show,
  onClose,
  ignoreOutsideClick = false,
}) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation();

  const handleClose = () => {
    if (!ignoreOutsideClick) {
      onClose();
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative" onClose={handleClose}>
        <div className="fixed inset-0 bg-gray-400/25" />

        <div className="fixed inset-0 overflow-hidden z-999">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 bottom-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="pointer-events-auto w-full">
                  <div className="webkit-full-h flex h-screen flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex items-center justify-between px-4 h-16 sm:px-6 border-b">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {t('bookAppointment')}
                      </Dialog.Title>
                      <button
                        type="button"
                        className="relative w-9 h-9 flex items-center justify-center rounded-full group"
                        onClick={onClose}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">{t('close')}</span>
                        <CloseIcon
                          title="close voice recorder"
                          width={20}
                          height={20}
                          strokeWidth={28}
                          strokeColor="text-gray-500 group-hover:text-gray-700"
                          classes="stroke-current"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                    <div className="relative flex w-full h-full flex-col bg-white shadow-xl">
                      <DrawerContainer className="relative flex-1 px-5 overflow-y-auto overflow-x-hidden">
                        <BookAppointment
                          appointmentType={appointmentType}
                          member={member}
                          setShowBookingModal={setShowBookingModal}
                          footerRef={footerRef}
                        />
                      </DrawerContainer>
                      <div
                        className="w-full h-24 bg-white flex items-center justify-center sticky bottom-0 border-t px-5"
                        ref={footerRef}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
