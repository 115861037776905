import * as Yup from 'yup';

const InternationalProviderProfileTwoSchema = Yup.object().shape({
  pronouns: Yup.string().required('Pronouns is required'),
  bio: Yup.string().required('Introductory Bio is required'),
  education: Yup.string().required('Education is required'),
  therapyTypes: Yup.array()
    .of(Yup.string())
    .required('Therapy Types are required'),
  conditions: Yup.array().of(Yup.string()).required('Conditions are required'),
  languages: Yup.array().of(Yup.string()).required('Languages are required'),
  ethnicity: Yup.array().of(Yup.string()).required('Ethnicity is required'),
  specificCulturalValues: Yup.string(),
});

export default InternationalProviderProfileTwoSchema;
