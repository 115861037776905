import React, {FC} from 'react';
import {Button, FormError, FormLabel, FormTextArea} from 'components';
import {UpdateMedicationsSchema} from 'definitions/Yup';
import {selectUserProfile} from 'features/User';
import {useEnhancedForm} from 'hooks';
import {SliceStatus, UpdateMedicationsFormData, UserRoles} from 'interfaces';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {providerActions} from '../providerSlice';

const HeaderContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
`;
type Props = {
  patientId: string;
};

const UpdateMedication: FC<Props> = ({patientId}) => {
  const user = useSelector(selectUserProfile);

  const defaultValues = {
    updateMedication: '',
  };
  const {
    formMethods: {
      register,
      formState: {errors},
      reset,
    },
    apiErrorMsg,
    isLoading,
    onSubmit,
  } = useEnhancedForm<
    UpdateMedicationsFormData,
    {patientId: string; role: UserRoles}
  >({
    defaultValues,
    schema: UpdateMedicationsSchema,
    slice: 'provider',
    action: providerActions.updateMedications,
    extraSubmitData: {patientId, role: user?.role ?? UserRoles.therapist},
  });

  const isPending = isLoading === SliceStatus.pending;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    onSubmit(event);
    reset();
  };

  return (
    <section className="relative">
      <HeaderContainer className="border-b flex items-center pt-3">
        <p className="font-bold text-lg pl-3">Update Medication</p>
      </HeaderContainer>
      <form onSubmit={handleSubmit}>
        <article className="mt-4 p-5 rounded-lg w-full text-center">
          <FormLabel
            htmlFor="updateMedication"
            className="font-medium pb-1 text-lg"
          >
            Medication
          </FormLabel>
          <FormTextArea
            id="updateMedication"
            name="updateMedication"
            register={register}
            className="rounded-lg"
            placeholder="Medication"
          />
          <FormError error={errors.updateMedication?.message || apiErrorMsg} />
        </article>
        <Button
          type="submit"
          borderColor="transparent"
          disabled={isPending}
          bgColor="blue-600"
          className="mb-8 py-2 px-4 ml-12 flex items-center justify-center rounded-full"
        >
          Update Medication
        </Button>
      </form>
    </section>
  );
};
export default UpdateMedication;
