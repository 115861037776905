import React, {FC, Fragment, useEffect, useState} from 'react';
import classNames from 'classnames';
import {ArrowDownIcon} from 'components/Basic/SvgIcon';
import dayjs from 'dayjs';
import {DateTimePickerWrapper} from 'features/Appointment/Calendar2/CalendarContainer.styles';
import {useCalendarLocalization} from 'hooks/useCalendarLocalization';
import {padZero, Time, TimePeriod} from 'interfaces';
import DayPickerInput from 'react-day-picker/DayPicker';
import styled from 'styled-components';
import {generateTimeArray} from 'utils';

import {Popover, Transition} from '@headlessui/react';

const Scroller = styled.div`
  ::-webkit-scrollbar {
    width: 5px !important;
    background: #000 !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #999 !important;
    border: none !important;
  }

  ::-webkit-scrollbar-track {
    background: none !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999 !important;
  }
`;

export const DateTimePicker: FC<{
  label: string;
  value: Time & {date: Date};
  onChange: (value: Time & {date: Date}) => void;
}> = ({label, value: _value, onChange}) => {
  const hour = generateTimeArray(13, '0');
  const minute = generateTimeArray(60, '0');
  const period = ['AM', 'PM'] as TimePeriod[];
  const {months, weekdaysLong, weekdaysShort, locale, firstDayOfWeek} =
    useCalendarLocalization();

  const [showScroll, setShowScroll] = useState({
    hour: false,
    minute: false,
    period: false,
  });

  const [value, setValue] = useState<Time & {date: Date}>(
    _value || {hour: 0, minute: 0, period: 'PM', date: new Date()},
  );

  useEffect(() => {
    if (value)
      onChange({
        hour: value.hour,
        minute: value.minute,
        period: value.period,
        date: value.date,
      });
  }, [value]);

  const handleDayClick = (day: Date) => {
    setValue({...value, date: day});
  };

  return (
    <Popover className="w-full">
      {({open}) => (
        <div className="w-full h-full">
          <Popover.Button
            className={classNames(
              'w-full h-12 flex justify-between items-center relative rounded-lg px-3 border border-gray-400',
              {
                'border-primary shadow-outline': open,
              },
            )}
          >
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-left text-[11px] font-medium text-[#747474]"
              >
                {label}
              </label>
              <div className="block w-full text-gray-900 text-xs text-left font-medium">
                {dayjs(_value.date).format('ddd, MMM D')},&nbsp;
                {padZero(_value.hour)}:{padZero(_value.minute)}&nbsp;
                {_value.period}
              </div>
            </div>
            <div>
              <ArrowDownIcon />
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="fixed left-1/2 z-999 mt-1 w-[460px] h-auto bg-white -translate-x-1/2 transform mx-0 border border-[#e3e3e3] rounded-2xl shadow-lg overflow-hidden">
              <div className="bg-white flex h-auto p-2.5">
                <DateTimePickerWrapper className="w-[242px] h-auto min-h-[230px] mr-[20px] font-inter">
                  <DayPickerInput
                    showOutsideDays
                    onDayClick={handleDayClick}
                    selectedDays={[value.date]}
                    disabledDays={{before: new Date()}}
                    className="!font-inter font-medium"
                    showWeekDays={false}
                    month={value.date}
                    months={months}
                    weekdaysLong={weekdaysLong}
                    weekdaysShort={weekdaysShort}
                    locale={locale}
                    firstDayOfWeek={firstDayOfWeek}
                  />
                </DateTimePickerWrapper>
                <div className="w-[180px] h-auto bg-white grid grid-cols-3 justify-center gap-1 overflow-hidden">
                  <div className="flex flex-col items-center bg-gray-200  border rounded-xl p-2.5 h-[230px]">
                    <div className="text-sm flex justify-center items-center bg-white rounded-lg border  font-inter font-semibold px-[6px] py-[8px] w-[35px] h-[35px]">
                      H
                    </div>
                    <Scroller
                      className={classNames(
                        'h-auto border-l flex flex-col items-center mt-[2px] gap-0.5 p-0.5 overflow-hidden',
                        {
                          'overflow-y-auto': showScroll.hour,
                        },
                      )}
                      onMouseEnter={() =>
                        setShowScroll({
                          ...showScroll,
                          hour: true,
                          minute: false,
                        })
                      }
                      onMouseLeave={() =>
                        setShowScroll({...showScroll, hour: false})
                      }
                    >
                      {hour.map(h => (
                        <button
                          key={h}
                          className={classNames(
                            'font-inter rounded-lg text-sm font-normal p-[5px] w-[35px] h-[27px] flex items-center justify-center',
                            {
                              'bg-blue-600 text-white':
                                value && value.hour === Number(h),
                              'hover:bg-[#E6E6E6] text-black':
                                value && value.hour !== Number(h),
                            },
                          )}
                          type="button"
                          onClick={() => setValue({...value, hour: Number(h)})}
                        >
                          {h}
                        </button>
                      ))}
                    </Scroller>
                  </div>
                  <div className="flex flex-col items-center bg-gray-200 border  rounded-xl p-2.5 h-[230px]">
                    <div className="text-sm flex justify-center items-center bg-white rounded-lg border  font-inter font-semibold px-[6px] py-[8px] w-[35px] h-[35px]">
                      M
                    </div>
                    <Scroller
                      className={classNames(
                        'h-full border-l flex flex-col items-center mt-[2px] gap-0.5 p-0.5 overflow-hidden',
                        {
                          'overflow-y-auto': showScroll.minute,
                        },
                      )}
                      onMouseEnter={() =>
                        setShowScroll({
                          ...showScroll,
                          minute: true,
                          hour: false,
                        })
                      }
                      onMouseLeave={() =>
                        setShowScroll({...showScroll, minute: false})
                      }
                    >
                      {minute.map(m => (
                        <button
                          key={m}
                          className={classNames(
                            'font-inter rounded-lg text-sm font-normal p-[5px] w-[35px] h-[27px] flex items-center justify-center',
                            {
                              'bg-blue-600 text-white':
                                value && value.minute === Number(m),
                              'hover:bg-[#E6E6E6] text-black':
                                value && value.minute !== Number(m),
                            },
                          )}
                          type="button"
                          onClick={() =>
                            setValue({...value, minute: Number(m)})
                          }
                        >
                          {m}
                        </button>
                      ))}
                    </Scroller>
                  </div>
                  <div className="flex flex-col items-center bg-gray-200 border  rounded-xl p-2.5 h-24">
                    <Scroller
                      className={classNames(
                        'h-auto border-l flex flex-col items-center gap-0.5',
                        {
                          'overflow-y-auto': showScroll.period,
                        },
                      )}
                      onMouseEnter={() =>
                        setShowScroll({
                          ...showScroll,
                          period: true,
                          hour: false,
                          minute: false,
                        })
                      }
                      onMouseLeave={() =>
                        setShowScroll({...showScroll, period: false})
                      }
                    >
                      {period.map(p => (
                        <button
                          key={p}
                          className={classNames(
                            'font-inter w-[35px] h-[35px] rounded-lg py-1 text-sm font-semibold flex items-center justify-center',
                            {
                              'bg-blue-600 text-white':
                                value && value.period === p,
                              'hover:bg-[#E6E6E6] text-black':
                                value && value.period !== p,
                            },
                          )}
                          type="button"
                          onClick={() => setValue({...value, period: p})}
                        >
                          {p}
                        </button>
                      ))}
                    </Scroller>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};
