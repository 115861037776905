import React, {FC, Fragment, useCallback, useEffect, useState} from 'react';
import ModalError from 'assets/images/modal-error.png';
import ModalErrorWebp from 'assets/images/modal-error.webp';
import ModalError2x from 'assets/images/modal-error@2x.png';
import ModalErrorWebp2x from 'assets/images/modal-error@2x.webp';
import ModalError3x from 'assets/images/modal-error@3x.png';
import ModalErrorWebp3x from 'assets/images/modal-error@3x.webp';
import ModalWarning from 'assets/images/modal-warning.png';
import ModalWarningWebp from 'assets/images/modal-warning.webp';
import ModalWarning2x from 'assets/images/modal-warning@2x.png';
import ModalWarningWebp2x from 'assets/images/modal-warning@2x.webp';
import ModalWarning3x from 'assets/images/modal-warning@3x.png';
import ModalWarningWebp3x from 'assets/images/modal-warning@3x.webp';
import classnames from 'classnames';
import {userActions} from 'features/User';
import {LazyImage, useToggle} from 'hooks';
//@ts-ignore
import lqip from 'lqip.macro';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {history} from 'utils';

import {Dialog, Transition} from '@headlessui/react';

import {Button} from '../Button';
import {LoadingSpinner} from '../Loader';
import {CloseIcon, SuccessIcon} from '../SvgIcon';

import {Content} from './styled';

const modalWarningLqip = lqip('../../../assets/images/modal-warning.png');
const modalErrorLqip = lqip('../../../assets/images/modal-error.png');

type Props = {
  messageType: 'success' | 'warning' | 'error' | 'none';
  message?: string;
  isOpen: boolean;
  buttonFn?: (fromOutside: boolean) => void;
  children?: React.ReactNode;
  infiniteScrollBehavior?: boolean;
  title?: string;
  titleHeight?: string;
  titleClasses?: string;
  floatingTitle?: boolean;
  showCloseIcon?: boolean;
  footer?: React.ReactNode;
  classes?: string;
  containerClasses?: string;
  headerClasses?: string;
  modalType?: 'normal' | 'release_notes';
  isContentLoading?: boolean;
  closeIconClasses?: string;
  subTitle?: string;
  footerClasses?: string;
  footerHeight?: string;
  backdropClasses?: string;
  zIndex?: number;
  isInProgress?: boolean;
};

const getModalData = (
  messageType: 'success' | 'warning' | 'error' | 'none',
  title?: string,
) => {
  const {t} = useTranslation();
  if (messageType === 'success')
    return {
      icon: <SuccessIcon />,
      title: t('modal.successful', 'Successful'),
      btnType: 'custom',
      buttonColor: 'blue-600',
    };
  else if (messageType === 'warning')
    return {
      icon: (
        <LazyImage
          lqip={modalWarningLqip}
          src={ModalWarning}
          srcSet={`${ModalWarning}, ${ModalWarning2x} 2x, ${ModalWarning3x}`}
          webp={`${ModalWarningWebp}, ${ModalWarningWebp2x} 2x, ${ModalWarningWebp3x}`}
          type="image/png"
          className="w-24 object-contain"
          aspectRatio={1 / 1}
          alt="Warning Sign"
        />
      ),
      title: t('modal.somethingWentWrong', 'Something went wrong'),
      btnType: 'custom',
      buttonColor: 'yellow-200',
    };
  else if (messageType === 'error')
    return {
      icon: (
        <LazyImage
          lqip={modalErrorLqip}
          src={ModalError}
          srcSet={`${ModalError}, ${ModalError2x} 2x, ${ModalError3x}`}
          webp={`${ModalErrorWebp}, ${ModalErrorWebp2x} 2x, ${ModalErrorWebp3x}`}
          type="image/png"
          className="w-24 object-contain"
          aspectRatio={1 / 1}
          alt="Error Sign"
        />
      ),
      title: t('modal.error', 'Error'),
      btnType: 'danger',
      buttonColor: 'red-500',
    };
  else if (messageType === 'none')
    return {
      icon: null,
      title,
      btnType: 'custom',
      buttonColor: '',
    };
  else return {icon: null, title: '', btnType: undefined, buttonColor: ''};
};

const Modal: FC<Props> = ({
  modalType = 'normal',
  message = '',
  messageType,
  isOpen,
  buttonFn,
  children,
  showCloseIcon = false,
  footer,
  classes,
  titleClasses,
  containerClasses,
  headerClasses,
  title,
  titleHeight = '48px',
  infiniteScrollBehavior = true,
  isContentLoading = false,
  floatingTitle = true,
  closeIconClasses = 'text-gray-400 hover:text-gray-600',
  subTitle,
  footerClasses,
  footerHeight = '96px',
  backdropClasses = '',
  zIndex = 9999,
  isInProgress = false,
}) => {
  const modalData = getModalData(messageType, title);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(0);
  const {toggleOn, onToggleClick} = useToggle(isOpen);

  const handleModalClose = useCallback(
    (fromOutside: boolean): void => {
      if (buttonFn) {
        buttonFn(fromOutside);
      }
      dispatch(
        userActions.resetNotification({
          messageType: 'none',
          message: '',
        }),
      );
      onToggleClick();
    },
    [buttonFn, dispatch, onToggleClick],
  );

  useEffect(() => {
    const handleEscKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) handleModalClose(false);
    };
    document.addEventListener('keydown', handleEscKeyDown);
    return () => {
      document.removeEventListener('keydown', handleEscKeyDown);
    };
  }, [handleModalClose, isOpen]);

  const isCoPay = message.match(/Book with your co-pay price/i);
  const isScrolled = !floatingTitle || scrollPosition > 0;

  const formattedMessage = toggleOn ? (
    <section>
      <section
        className={classnames(`${messageType !== 'success' && 'border-b '}`, {
          'pb-4': isCoPay,
        })}
      >
        <button className="opacity-0 absolute bottom-0"></button>
        <div className="mx-auto flex items-center justify-center w-20 h-20 md:w-24 md:h-24 rounded-full">
          {modalData.icon}
        </div>
        <div className="text-center mt-4">
          <h3 className="text-xl font-semibold md:text-3xl leading-snug">
            {modalData.title}
          </h3>

          {isCoPay ? null : (
            <div className="pt-1 md:pt-2">
              <p className="text-sm leading-snug text-gray-600">{message}</p>
            </div>
          )}
        </div>
      </section>
      <section className="py-5 md:py-10 text-center">
        <Button
          type="button"
          borderColor="transparent"
          className={`${
            messageType === 'success' ? 'w-1/3' : 'w-full md:w-1/3'
          } py-3 shadow-sm
          `}
          bgColor={modalData.buttonColor}
          btnType={modalData.btnType as any}
          onClick={() => handleModalClose(false)}
          fontWeight="semibold"
        >
          {isCoPay ? message : t('modal.continue', 'Continue')}
        </Button>

        {isCoPay ? (
          <section className="mt-6">
            <p className="text-gray-500 500 text-sm">OR</p>
            <p
              className="text-gray-500 text-sm mt-4 underline cursor-pointer"
              onClick={() => {
                history.push('/book-appointment/chat_with_coach');
                dispatch(
                  userActions.resetNotification({
                    messageType: 'none',
                    message: '',
                  }),
                );
              }}
            >
              {t('modal.checkFreeSession', 'Check FREE coaching sessions.')}
            </p>
          </section>
        ) : null}
      </section>
    </section>
  ) : null;

  const showLoader = () =>
    isContentLoading ? (
      <div className="w-full h-full flex flex-col justify-center items-center bg-white bg-opacity-60 backdrop-blur-lg absolute inset-0">
        <LoadingSpinner height={40} type="Oval" color="#315eff" />
      </div>
    ) : null;

  const contentHeight = footerHeight
    ? `calc(${titleHeight} + ${footerHeight})`
    : 0;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        style={{zIndex}}
        onClose={() => {
          if (isInProgress) return;
          // eslint-disable-next-line no-restricted-globals
          const target = event?.target as HTMLElement;
          const targetClass = target?.className;
          if (targetClass?.includes('modal-backdrop')) {
            handleModalClose(true);
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classnames(
              'fixed inset-0 bg-gray-100 bg-opacity-75',
              backdropClasses,
            )}
          />
        </Transition.Child>

        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center modal-backdrop">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={
                  containerClasses ??
                  'w-full max-h-[90vh] bg-white md:max-w-xl 2xl:max-w-2xl overflow-hidden transform rounded-lg text-left align-middle transition-all pb-4 md:pb-6 shadow-lg'
                }
              >
                {showCloseIcon ? (
                  <div
                    className={classnames(
                      headerClasses ??
                        `w-full h-[${titleHeight}] px-4 flex justify-end items-center `,
                      {
                        'border-b': isScrolled && !title,
                        'border-b justify-between': isScrolled && title,
                      },
                      titleClasses,
                    )}
                  >
                    <div className="flex flex-col">
                      {title && isScrolled ? (
                        <p className={titleClasses ?? 'font-semibold'}>
                          {modalData.title}
                        </p>
                      ) : null}
                      {subTitle && (
                        <p className="text-sm text-gray-500">{subTitle}</p>
                      )}
                    </div>
                    <button
                      className={classnames(
                        'focus:outline-none rounded-full w-8 h-8 flex items-center justify-center p-0.5',
                        closeIconClasses,
                      )}
                      onClick={() => {
                        if (isInProgress) return;
                        handleModalClose(false);
                      }}
                      key="closeButton"
                    >
                      <CloseIcon strokeColor="text-inherit" strokeWidth={40} />
                    </button>
                  </div>
                ) : null}

                <Content
                  modalType={modalType}
                  maxHeight={
                    showCloseIcon
                      ? `calc(90vh - ${
                          contentHeight ? contentHeight : titleHeight
                        })`
                      : '90vh'
                  }
                  className={classnames(
                    `px-4 md:px-6 relative`,
                    {
                      'pt-7': !showCloseIcon,
                      'overflow-hidden': isContentLoading,
                      'overflow-y-auto': !isContentLoading,
                    },
                    classes,
                  )}
                  id={infiniteScrollBehavior ? 'scrollableDiv' : undefined}
                  onScroll={e => setScrollPosition(e.currentTarget.scrollTop)}
                >
                  {modalData.icon && children ? (
                    <div className="mx-auto flex items-center justify-center w-20 h-20 md:w-24 md:h-24 rounded-full mb-5">
                      {modalData.icon}
                    </div>
                  ) : null}
                  {children ?? formattedMessage}
                  {showLoader()}
                </Content>

                {footer ? (
                  <div
                    className={classnames(
                      `w-full h-[${footerHeight}] flex items-center justify-end gap-2 py-6 px-4 md:px-6 border-t`,
                      footerClasses,
                    )}
                  >
                    {footer}
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
