import {MemberProfile, UserRoles} from 'interfaces';
import {Meeting} from 'interfaces/Meeting.types';

import {createAction} from '@reduxjs/toolkit';

export const setAppointmentPrice = createAction<{
  providerType?: UserRoles;
  appointmentID: string;
  charge: number;
  chargeCurrency: string;
  patientId: string;
  startDate: string;
  callback: () => void;
  closeModal: () => void;
}>('SetAppointmentPrice');
export const setAppointmentPriceSuccess = createAction<{message: Meeting}>(
  'SetAppointmentPriceSuccess',
);
export const setAppointmentPriceFailure = createAction<{
  message: string;
}>('SetAppointmentPriceFailure');
export const chargeDigitalPracticeAppointment = createAction<{
  patientId: string;
  appointmentID: string;
  isDigitalPracticeOutOfPocketMember: boolean;
  providerType: UserRoles;
  endDate: string;
  startDate: string;
  callback: () => void;
  closeModal: () => void;
}>('ChargeDigitalPracticeAppointment');
export const chargeDigitalPracticeAppointmentSuccess = createAction<{
  message: Meeting;
}>('ChargeDigitalPracticeAppointmentSuccess');
export const chargeDigitalPracticeAppointmentFailure = createAction<{
  message: string;
}>('ChargeDigitalPracticeAppointmentFailure');

export const payLaterRequest = createAction<{
  appointmentID: string;
  startDate: string;
  callback: () => void;
  closeModal: () => void;
}>('PayLaterRequest');
export const payLaterRequestSuccess = createAction<{
  message: MemberProfile;
}>('PayLaterRequestSuccess');
export const payLaterRequestFailure = createAction<{
  message: string;
}>('PayLaterRequestFailure');

export const standardCheckoutChargeSuccess = createAction<{
  message: Partial<Meeting>;
}>('standardCheckoutChargeSuccess');

export const getMeetingDetails = createAction<{
  appointmentID: number;
  providerId: string;
  providerType: UserRoles;
}>('GetMeetingDetails');

export const getMeetingDetailsSuccess = createAction<{message: Meeting}>(
  'GetMeetingDetailsSuccess',
);
export const getMeetingDetailsFailure = createAction(
  'GetMeetingDetailsFailure',
);
