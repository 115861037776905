import {
  GenerateMagicContentArgs,
  GenerateMagicContentResponse,
  MagicContentArticle,
  SliceStatus,
  UnsplashImage,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  checkIfProviderReachedDailyMagicContentLimit,
  checkIfProviderReachedDailyMagicContentLimitFailure,
  checkIfProviderReachedDailyMagicContentLimitSuccess,
  generateExpertSummary,
  generateExpertSummaryFailure,
  generateExpertSummarySuccess,
  generateMagicContent,
  generateMagicContentFailure,
  generateMagicContentSuccess,
  getMagicArticleBySlug,
  getMagicArticleBySlugFailure,
  getMagicArticleBySlugSuccess,
  getMagicArticlesByProvider,
  getMagicArticlesByProviderFailure,
  getMagicArticlesByProviderSuccess,
  getMagicImages,
  getMagicImagesFailure,
  getMagicImagesSuccess,
  reGenerateMagicContent,
  reGenerateMagicContentFailure,
  reGenerateMagicContentSuccess,
  resetMagicContent,
  saveMagicArticle,
  saveMagicArticleFailure,
  saveMagicArticleSuccess,
  setMagicArticle,
  triggerDownloadUnsplashPhotoEvent,
  triggerDownloadUnsplashPhotoEventFailure,
  triggerDownloadUnsplashPhotoEventSuccess,
} from './magicContentActions';

type MagicContentSliceState = {
  status: SliceStatus;
  saveStatus: SliceStatus;
  content?: GenerateMagicContentResponse;
  article?: MagicContentArticle;
  articles?: MagicContentArticle[];
  topicSelection?: GenerateMagicContentArgs['topicSelection'];
  regenerateStatus: {[key: string]: SliceStatus};
  images: UnsplashImage[];
  imagesStatus: SliceStatus;
  providerCanCreate?: boolean;
  error: string;
};

export const magicContentSliceInitialState: MagicContentSliceState = {
  status: SliceStatus.idle,
  content: undefined,
  article: undefined,
  saveStatus: SliceStatus.idle,
  images: [],
  imagesStatus: SliceStatus.idle,
  regenerateStatus: {},
  error: '',
};

const magicContentSlice = createSlice({
  name: 'magicContent',
  initialState: magicContentSliceInitialState as MagicContentSliceState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<MagicContentSliceState>) =>
    builder
      .addCase(generateExpertSummary, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(generateExpertSummarySuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(generateExpertSummaryFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(setMagicArticle, (state, action) => ({
        ...state,
        article: action.payload,
      }))
      .addCase(resetMagicContent, state => ({
        ...state,
        content: undefined,
        article: undefined,
        topicSelection: undefined,
      }))
      .addCase(generateMagicContent, (state, payload) => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
        topicSelection: payload.payload.topicSelection,
      }))
      .addCase(generateMagicContentSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        content: action.payload,
        error: '',
      }))
      .addCase(generateMagicContentFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(saveMagicArticle, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(saveMagicArticleSuccess, (state, action) => ({
        ...state,
        article: action.payload,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(saveMagicArticleFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getMagicImages, state => ({
        ...state,
        imagesStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(getMagicImagesSuccess, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.resolved,
        images: action.payload.results,
        error: '',
      }))
      .addCase(getMagicImagesFailure, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getMagicArticleBySlug, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(getMagicArticleBySlugSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        article: action.payload,
        error: '',
      }))
      .addCase(getMagicArticleBySlugFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getMagicArticlesByProvider, state => ({
        ...state,
        articles: [],
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(getMagicArticlesByProviderSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        articles: action.payload,
        error: '',
      }))
      .addCase(getMagicArticlesByProviderFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(checkIfProviderReachedDailyMagicContentLimit, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(
        checkIfProviderReachedDailyMagicContentLimitSuccess,
        (state, action) => ({
          ...state,
          status: SliceStatus.resolved,
          providerCanCreate: action.payload,
          error: '',
        }),
      )
      .addCase(
        checkIfProviderReachedDailyMagicContentLimitFailure,
        (state, action) => ({
          ...state,
          status: SliceStatus.rejected,
          providerCanCreate:
            action.payload === 'You have reached the daily post limit',
          error: action.payload,
        }),
      )
      .addCase(reGenerateMagicContent, (state, action) => ({
        ...state,
        regenerateStatus: {
          ...state.regenerateStatus,
          [action.payload.element]: SliceStatus.pending,
        },
        error: '',
      }))
      .addCase(reGenerateMagicContentSuccess, (state, action) => {
        const content = {
          ...((state.content ?? {}) as GenerateMagicContentResponse),
        };
        const {element, ...data} = action.payload;
        Object.keys(data).forEach(key => {
          content[key] = data[key];
        });

        return {
          ...state,
          regenerateStatus: {
            ...state.regenerateStatus,
            [element]: SliceStatus.resolved,
          },
          content,
          error: '',
        };
      })
      .addCase(reGenerateMagicContentFailure, (state, action) => ({
        ...state,
        regenerateStatus: {
          ...state.regenerateStatus,
          [action.payload.element]: SliceStatus.rejected,
        },
        error: action.payload.error,
      })),
});

export const {reducer: magicContentReducer, name: magicContentReducerName} =
  magicContentSlice;

export type TMagicContentActions =
  | ReturnType<typeof generateExpertSummary>
  | ReturnType<typeof generateExpertSummarySuccess>
  | ReturnType<typeof generateExpertSummaryFailure>
  | ReturnType<typeof setMagicArticle>
  | ReturnType<typeof resetMagicContent>
  | ReturnType<typeof generateMagicContent>
  | ReturnType<typeof generateMagicContentSuccess>
  | ReturnType<typeof generateMagicContentFailure>
  | ReturnType<typeof reGenerateMagicContent>
  | ReturnType<typeof reGenerateMagicContentSuccess>
  | ReturnType<typeof reGenerateMagicContentFailure>
  | ReturnType<typeof saveMagicArticle>
  | ReturnType<typeof saveMagicArticleSuccess>
  | ReturnType<typeof saveMagicArticleFailure>
  | ReturnType<typeof getMagicImages>
  | ReturnType<typeof getMagicImagesSuccess>
  | ReturnType<typeof getMagicImagesFailure>
  | ReturnType<typeof getMagicArticleBySlug>
  | ReturnType<typeof getMagicArticleBySlugSuccess>
  | ReturnType<typeof getMagicArticleBySlugFailure>
  | ReturnType<typeof getMagicArticlesByProvider>
  | ReturnType<typeof getMagicArticlesByProviderSuccess>
  | ReturnType<typeof getMagicArticlesByProviderFailure>
  | ReturnType<typeof checkIfProviderReachedDailyMagicContentLimit>
  | ReturnType<typeof checkIfProviderReachedDailyMagicContentLimitSuccess>
  | ReturnType<typeof checkIfProviderReachedDailyMagicContentLimitFailure>
  | ReturnType<typeof triggerDownloadUnsplashPhotoEvent>
  | ReturnType<typeof triggerDownloadUnsplashPhotoEventSuccess>
  | ReturnType<typeof triggerDownloadUnsplashPhotoEventFailure>;

export const magicContentActions = {
  generateExpertSummary,
  generateExpertSummarySuccess,
  generateExpertSummaryFailure,
  resetMagicContent,
  setMagicArticle,
  generateMagicContent,
  generateMagicContentSuccess,
  generateMagicContentFailure,
  reGenerateMagicContent,
  reGenerateMagicContentSuccess,
  reGenerateMagicContentFailure,
  saveMagicArticle,
  saveMagicArticleSuccess,
  saveMagicArticleFailure,
  getMagicImages,
  getMagicImagesSuccess,
  getMagicImagesFailure,
  getMagicArticleBySlug,
  getMagicArticleBySlugSuccess,
  getMagicArticleBySlugFailure,
  getMagicArticlesByProvider,
  getMagicArticlesByProviderSuccess,
  getMagicArticlesByProviderFailure,
  checkIfProviderReachedDailyMagicContentLimit,
  checkIfProviderReachedDailyMagicContentLimitSuccess,
  checkIfProviderReachedDailyMagicContentLimitFailure,
  triggerDownloadUnsplashPhotoEvent,
  triggerDownloadUnsplashPhotoEventSuccess,
  triggerDownloadUnsplashPhotoEventFailure,
};

export type MagicContentState = ReturnType<typeof magicContentReducer>;
