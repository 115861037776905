import React, {lazy} from 'react';
import {
  AuthLayout,
  DashboardLayout,
  DashboardLayoutV2,
  DefaultLayout,
  MainLayout,
  MemberProfileLayout,
  VideoLayout,
} from 'components';
import {PrivateRoute} from 'components/HOCs';
import {ProviderProfileLayout} from 'components/Layouts/ProviderProfileLayout';
import {selectUserProfile} from 'features/User';
import WherebyVideoPage from 'features/Video/WherebyVideo/WherebyVideoPage';
import {ProviderFormWorkflow, UserRoles} from 'interfaces';
import {useSelector} from 'react-redux';
import {componentLoader, isProvider} from 'utils';

const BookingPage = lazy(() =>
  componentLoader(() => import('features/Appointment/Booking')),
);
const RescheduleAppointmentPage = lazy(() =>
  componentLoader(() => import('features/Appointment/RescheduleAppointment')),
);
const OnboardingLobbyPage = lazy(() =>
  componentLoader(
    () => import('features/Appointment/Onboarding/OnboardingLobby'),
  ),
);
const OnboardingIntakePage = lazy(() =>
  componentLoader(
    () =>
      import(
        'features/Appointment/Onboarding/PrescriberAppointments/MedicalIntake'
      ),
  ),
);
const CheckoutPage = lazy(() =>
  componentLoader(() => import('features/Payment/Checkout')),
);
const MembershipPage = lazy(() =>
  componentLoader(() => import('features/Payment/Membership')),
);
const CancelSubscriptionPage = lazy(() =>
  componentLoader(
    () => import('features/Payment/ManageSubscription/CancellationFlow'),
  ),
);
const ResubscriptionCheckoutPage = lazy(() =>
  componentLoader(() => import('features/Payment/ResubscriptionCheckout')),
);
const ResubscriptionPlansPage = lazy(() =>
  componentLoader(() => import('features/Payment/ResubscriptionPlans')),
);
const StripePaymentPage = lazy(() =>
  componentLoader(() => import('features/Payment/StripePayment')),
);
const OnboardingOneTimePaymentPage = lazy(() =>
  componentLoader(() => import('features/Payment/OnboardingOneTimePayment')),
);

const UpdatePlanPage = lazy(() =>
  componentLoader(() => import('features/Payment/UpdatePlan')),
);
const AddCreditCardPage = lazy(() =>
  componentLoader(() => import('features/Payment/AddCreditCard')),
);
const DashboardPage = lazy(() =>
  componentLoader(() => import('features/Member/Home')),
);
const ProfilePage = lazy(() =>
  componentLoader(() => import('features/Member/Profile/ProfilePage')),
);
const AccountPage = lazy(() =>
  componentLoader(() => import('features/Member/Profile')),
);

const TermsPage = lazy(() =>
  componentLoader(() => import('features/Member/TandC')),
);
const CreditCard = lazy(() =>
  componentLoader(() => import('features/Payment/CreditCardMgmt')),
);
const ReceiptsPage = lazy(() =>
  componentLoader(() => import('features/Payment/Receipts')),
);
const ThankYouPage = lazy(() =>
  componentLoader(() => import('features/Member/Thanks')),
);

const WelcomePage = lazy(() =>
  componentLoader(() => import('features/Member/Welcome')),
);

const SingleNotificationPage = lazy(() =>
  componentLoader(() => import('features/Notification/Single')),
);

const NotificationsPage = lazy(() =>
  componentLoader(() => import('features/Notification/List')),
);

const SelfAwarenessPage = React.lazy(() =>
  componentLoader(() => import('features/Member/SelfAwarenessRequest')),
);
const ProgressPage = React.lazy(() =>
  componentLoader(() => import('features/Member/Progress')),
);
const UpdateProgressFormPage = React.lazy(() =>
  componentLoader(() => import('features/Member/Progress/UpdateProgressForm')),
);

const MemberDetailsPage = lazy(() =>
  componentLoader(() => import('features/Provider/Member')),
);

const MembersPage = lazy(() =>
  componentLoader(() => import('features/Provider/Members')),
);

const ProviderDashboardPage = lazy(() =>
  componentLoader(() => import('features/Provider/Dashboard')),
);

const ProviderProfilePage = lazy(() =>
  componentLoader(() => import('features/Provider/Profile')),
);
const ProviderProfileDetailsPage = lazy(() =>
  componentLoader(() => import('features/Provider/Profile/Details')),
);
const ProviderProfileInNetworkPage = lazy(() =>
  componentLoader(() => import('features/Provider/Profile/InNetwork')),
);
const ProviderProfileSettingsPage = lazy(() =>
  componentLoader(() => import('features/Provider/Profile/Settings')),
);
const ProviderProfileRevenuePage = lazy(() =>
  componentLoader(() => import('features/Provider/Profile/Revenue')),
);
const ProviderProfileFaqsPage = lazy(() =>
  componentLoader(() => import('features/Provider/Profile/FAQs')),
);

const ProviderCalendarPage = lazy(() =>
  componentLoader(() => import('features/Provider/Calendar2')),
);

const ProviderAvailabilityPage = lazy(() =>
  componentLoader(() => import('features/Provider/Availability')),
);

const MessagingPage = lazy(() =>
  componentLoader(() => import('features/Messaging/Comet')),
);

const DigitalProviderPage = lazy(() =>
  componentLoader(() => import('features/Provider/DigitalPractice')),
);

const DigitalPracticeMarketingPage = lazy(() =>
  componentLoader(
    () => import('features/Provider/DigitalPractice/DigitalPracticeMarketing'),
  ),
);

const DigitalCollaborationPage = lazy(() =>
  componentLoader(
    () => import('features/Provider/DigitalPractice/Collaboration'),
  ),
);

const MarketingPage = lazy(() =>
  componentLoader(() => import('features/Provider/Marketing')),
);

const RedirectAuth = lazy(() =>
  componentLoader(
    () => import('features/Provider/DigitalPractice/Revenue/RedirectAuth'),
  ),
);
const StripeOnboarded = lazy(() =>
  componentLoader(
    () => import('features/Provider/DigitalPractice/Revenue/StripeOnboarded'),
  ),
);
const SyncPayPalSellerInfoPage = lazy(() =>
  componentLoader(
    () =>
      import('features/Provider/DigitalPractice/Revenue/SyncPayPalSellerInfo'),
  ),
);
const VerifyPaystackTransaction = lazy(() =>
  componentLoader(
    () =>
      import(
        'features/Payment/DigitalPracticeCheckout/Paystack/VerifyPaystackCard'
      ),
  ),
);
const DigitalPracticeOptionsPage = lazy(() =>
  componentLoader(
    () =>
      import(
        'features/User/Register/OutsideWellniteRegister/DigitalPracticeOptions'
      ),
  ),
);
const ProviderProfileFormPage = lazy(() =>
  componentLoader(
    () => import('features/Provider/Register/Onboarding/ProfileForm'),
  ),
);

const ProviderCaqhFormPage = lazy(() =>
  componentLoader(
    () => import('features/Provider/Register/Onboarding/CaqhForm'),
  ),
);

const ProviderOnboardingFormPage = lazy(() =>
  componentLoader(
    () => import('features/Provider/Register/Onboarding/OnboardingForm'),
  ),
);

const ProviderDashboardOnboardingPage = lazy(() =>
  componentLoader(() => import('features/Provider/Register/Onboarding')),
);

const ProviderVerificationForm = lazy(() =>
  componentLoader(
    () => import('features/Provider/Register/Onboarding/Verification'),
  ),
);

const MemberIntakePage = lazy(() =>
  componentLoader(() => import('features/Intake')),
);

const InternationalProviderOnboarding = lazy(() =>
  componentLoader(
    () => import('features/Provider/Register/InternationalProviderOnboarding'),
  ),
);
const InternationalProviderTerms = lazy(() =>
  componentLoader(
    () =>
      import(
        'features/Provider/Register/InternationalProviderOnboarding/TermsOfServiceForm'
      ),
  ),
);
const InternationalProviderProofOfId = lazy(() =>
  componentLoader(
    () =>
      import(
        'features/Provider/Register/InternationalProviderOnboarding/ProofOfIdForm'
      ),
  ),
);
export const PrivateRoutes = (): JSX.Element[] => {
  const user = useSelector(selectUserProfile);

  return [
    <PrivateRoute
      exact
      path="/resubscribe-plan"
      key="/resubscribe-plan"
      component={ResubscriptionPlansPage}
      layoutProps={{lgColumns: 'grid-cols-1'}}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/(uninsured|mindfulness|together|therapy|medicalcare_plus_therapy|medicalcare|new_therapy)/resubscribe/stripe"
      key="/(uninsured|mindfulness|together|therapy|medicalcare_plus_therapy|medicalcare|new_therapy)/resubscribe/stripe"
      component={ResubscriptionCheckoutPage}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/together/one-time-payment"
      key="/together/one-time-payment"
      component={StripePaymentPage}
      layout={DashboardLayout}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/(uninsured|mindfulness|together|therapy|medicalcare_plus_therapy|medicalcare|new_therapy)/register/stripe"
      key="/(uninsured|mindfulness|together|therapy|medicalcare_plus_therapy|medicalcare|new_therapy)/register/stripe"
      component={CheckoutPage}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/(uninsured|mindfulness|together|therapy|medicalcare_plus_therapy|medicalcare|new_therapy|scale)/thanks"
      key="/(uninsured|mindfulness|together|therapy|medicalcare_plus_therapy|medicalcare|new_therapy|scale)/thanks"
      authorizedRoles={[UserRoles.member]}
      component={ThankYouPage}
    />,
    <PrivateRoute
      exact
      path="/dashboard"
      key="/dashboard"
      component={isProvider(user) ? ProviderDashboardPage : DashboardPage}
      layout={DashboardLayoutV2}
      authorizedRoles={[
        UserRoles.member,
        UserRoles.therapist,
        UserRoles.prescriber,
      ]}
    />,

    <PrivateRoute
      exact
      path="/member/:patientId"
      key="/member/:patientId"
      component={MemberDetailsPage}
      layout={DashboardLayoutV2}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
    />,

    <PrivateRoute
      exact
      path="/video-call/embed/wb"
      key="/video-call/embed/wb"
      component={WherebyVideoPage}
      layout={VideoLayout}
      authorizedRoles={[
        UserRoles.member,
        UserRoles.therapist,
        UserRoles.prescriber,
      ]}
    />,
    <PrivateRoute
      exact
      path="/book-appointment/:appointmentType"
      key="/book-appointment/:appointmentType"
      component={BookingPage}
      layout={DashboardLayout}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/reschedule-appointment/:appointmentType/:providerId"
      key="/reschedule-appointment/:appointmentType/:providerId"
      component={RescheduleAppointmentPage}
      layout={DashboardLayout}
      authorizedRoles={[UserRoles.member]}
    />,

    <PrivateRoute
      exact
      path="/progress"
      key="/progress"
      component={ProgressPage}
      layout={DashboardLayoutV2}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/progress/update"
      key="/progress/update"
      component={UpdateProgressFormPage}
      layout={DashboardLayoutV2}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/self-awareness"
      key="/self-awareness"
      component={SelfAwarenessPage}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/onboarding-lobby/:intakeType"
      key="/onboarding-lobby/:intakeType"
      component={OnboardingLobbyPage}
      layout={MainLayout}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/onboarding-medical-intake"
      key="/onboarding-medical-intake"
      component={OnboardingIntakePage}
      layout={MainLayout}
      authorizedRoles={[UserRoles.member]}
    />,

    <PrivateRoute
      exact
      path="/update-plan"
      key="/update-plan"
      component={UpdatePlanPage}
      layout={DashboardLayoutV2}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/account"
      key="/account"
      component={AccountPage}
      layout={DashboardLayoutV2}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/profile"
      key="/profile"
      component={ProfilePage}
      layout={MemberProfileLayout}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/notifications"
      key="/notifications"
      component={NotificationsPage}
      layout={DashboardLayoutV2}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/notifications/:notificationId"
      key="/notifications/:notificationId"
      component={SingleNotificationPage}
      layout={DashboardLayout}
      authorizedRoles={[UserRoles.member]}
    />,

    <PrivateRoute
      exact
      path="/membership"
      key="/membership"
      component={MembershipPage}
      layout={MemberProfileLayout}
      authorizedRoles={[UserRoles.member]}
    />,

    <PrivateRoute
      exact
      path="/terms-and-conditions"
      key="/terms-and-conditions"
      component={TermsPage}
      layout={MemberProfileLayout}
      authorizedRoles={[UserRoles.member]}
    />,

    <PrivateRoute
      exact
      path="/payment"
      key="/payment"
      component={CreditCard}
      layout={MemberProfileLayout}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/receipts"
      key="/receipts"
      component={ReceiptsPage}
      layout={MemberProfileLayout}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/cancel-subscription"
      key="/cancel-subscription"
      component={CancelSubscriptionPage}
      layout={DashboardLayout}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/add-credit-card"
      key="/add-credit-card"
      layout={DashboardLayoutV2}
      component={AddCreditCardPage}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/onboarding-one-time-payment"
      key="/onboarding-one-time-payment"
      component={OnboardingOneTimePaymentPage}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/member"
      key="/member"
      component={MemberDetailsPage}
      authorizedRoles={[
        UserRoles.therapist,
        UserRoles.prescriber,
        UserRoles.member,
      ]}
      layout={DashboardLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/view-members"
      key="/provider/view-members"
      component={MembersPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={DashboardLayoutV2}
    />,
    <PrivateRoute
      exact
      path="/provider/profile"
      key="/provider/profile"
      component={ProviderProfilePage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={DashboardLayoutV2}
    />,
    <PrivateRoute
      exact
      path="/provider/profile/details"
      key="/provider/profile/details"
      component={ProviderProfileDetailsPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={ProviderProfileLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/profile/in-network"
      key="/provider/profile/in-network"
      component={ProviderProfileInNetworkPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={ProviderProfileLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/profile/settings"
      key="/provider/profile/settings"
      component={ProviderProfileSettingsPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={ProviderProfileLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/profile/revenue"
      key="/provider/profile/revenue"
      component={ProviderProfileRevenuePage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={ProviderProfileLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/profile/receipts"
      key="/provider/profile/receipts"
      component={ReceiptsPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={ProviderProfileLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/profile/resource-center"
      key="/provider/profile/resource-center"
      component={ProviderProfileFaqsPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={ProviderProfileLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/calendar"
      key="/provider/calendar"
      component={ProviderCalendarPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={DashboardLayoutV2}
    />,
    <PrivateRoute
      exact
      path="/provider/calendar/:mode/:date"
      key="/provider/calendar/:mode/:date"
      component={ProviderCalendarPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={DashboardLayoutV2}
    />,

    <PrivateRoute
      exact
      path="/calendar"
      key="/calendar"
      component={ProviderCalendarPage}
      authorizedRoles={[UserRoles.member]}
      layout={DashboardLayoutV2}
    />,

    <PrivateRoute
      exact
      path="/provider/availability"
      key="/provider/availability"
      component={ProviderAvailabilityPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={DashboardLayoutV2}
    />,

    <PrivateRoute
      exact
      path="/provider/message"
      key="/provider/message"
      component={MessagingPage}
      authorizedRoles={[UserRoles.prescriber, UserRoles.therapist]}
      layout={DashboardLayoutV2}
    />,

    <PrivateRoute
      exact
      path="/message"
      key="/message"
      component={MessagingPage}
      authorizedRoles={[UserRoles.member]}
      layout={DashboardLayoutV2}
    />,

    <PrivateRoute
      exact
      path="/provider/digital-practice"
      key="/provider/digital-practice"
      component={DigitalProviderPage}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={DashboardLayout}
    />,

    <PrivateRoute
      exact
      path="/provider/digital-practice-marketing"
      key="/provider/digital-practice-marketing"
      component={DigitalPracticeMarketingPage}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={DashboardLayout}
    />,

    <PrivateRoute
      exact
      path="/stripe-connect/re-auth"
      key="/stripe-connect/re-auth"
      component={RedirectAuth}
      authorizedRoles={[UserRoles.therapist]}
      layout={DefaultLayout}
    />,
    <PrivateRoute
      exact
      path="/stripe-connect/onboarded"
      key="/stripe-connect/onboarded"
      component={StripeOnboarded}
      authorizedRoles={[UserRoles.therapist]}
      layout={DefaultLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/sync-paypal-seller-info"
      key="/provider/sync-paypal-seller-info"
      component={SyncPayPalSellerInfoPage}
      authorizedRoles={[UserRoles.therapist]}
      layout={DefaultLayout}
    />,
    <PrivateRoute
      exact
      path="/paystack/verify-card"
      key="/paystack/verify-card"
      component={VerifyPaystackTransaction}
      authorizedRoles={[UserRoles.member]}
      layout={DefaultLayout}
    />,
    <PrivateRoute
      exact
      path="/digital-practice/:providerId"
      key="/digital-practice/:providerId"
      component={DigitalPracticeOptionsPage}
      authorizedRoles={[UserRoles.member]}
    />,

    <PrivateRoute
      exact
      path="/provider/digital-practice/collaboration/:tabPosition?"
      key="/provider/digital-practice/collaboration/:tabPosition?"
      component={DigitalCollaborationPage}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={DashboardLayoutV2}
    />,
    <PrivateRoute
      exact
      path="/provider/marketing"
      key="/provider/marketing"
      component={MarketingPage}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={DashboardLayoutV2}
    />,
    <PrivateRoute
      exact
      path="/provider/registration/verification-form"
      key="/provider/registration/verification-form"
      component={ProviderVerificationForm}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={AuthLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/registration/caqh-form"
      key="/provider/registration/caqh-form"
      component={() => (
        <ProviderCaqhFormPage workflow={ProviderFormWorkflow.onboarding} />
      )}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={AuthLayout}
    />,
    <PrivateRoute
      exact
      path="/provider/registration/onboarding"
      key="/provider/registration/onboarding"
      component={ProviderDashboardOnboardingPage}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
    />,
    <PrivateRoute
      exact
      path="/provider/registration/profile-form"
      key="/provider/registration/profile-form"
      component={ProviderProfileFormPage}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={AuthLayout}
      layoutProps={{
        bgColor: 'bg-white',
      }}
    />,
    <PrivateRoute
      exact
      path="/provider/registration/onboarding-form"
      key="/provider/registration/onboarding-form"
      component={ProviderOnboardingFormPage}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={AuthLayout}
      layoutProps={{
        bgColor: 'bg-white',
      }}
    />,
    <PrivateRoute
      exact
      path="/onboarding-therapy-intake"
      key="/onboarding-therapy-intake"
      component={MemberIntakePage}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/welcome"
      key="/welcome"
      component={WelcomePage}
      authorizedRoles={[UserRoles.member]}
    />,
    <PrivateRoute
      exact
      path="/provider/international/registration"
      key="/provider/international/registration"
      component={InternationalProviderOnboarding}
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      layout={AuthLayout}
      layoutProps={{
        bgColor: 'bg-white',
      }}
    />,
    <PrivateRoute
      exact
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      path="/provider/international/registration/proof-of-id"
      key="/provider/international/registration/proof-of-id"
      component={InternationalProviderProofOfId}
      layout={AuthLayout}
    />,
    <PrivateRoute
      exact
      authorizedRoles={[UserRoles.therapist, UserRoles.prescriber]}
      path="/provider/international/registration/terms"
      key="/provider/international/registration/terms"
      component={InternationalProviderTerms}
      layout={MainLayout}
    />,
  ];
};
