import React, {FC, lazy, useState} from 'react';
import PlaceholderImage from 'assets/images/silhouette.jpg';
import {
  AccountIcon,
  AvailabilityIconV2,
  Button,
  LoadingSpinner,
} from 'components';
import {CircleCheckIcon, CurrencyDollarIcon} from 'components/Basic/SvgIcon';
import dayjs from 'dayjs';
import ChargeModal from 'features/Appointment/Charge/ChargeModal';
import {useProfile} from 'hooks';
import {PaypalStandardPaymentCountries} from 'interfaces';
import {ChargeStatus} from 'interfaces/Meeting.types';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {
  componentLoader,
  isDigitalPracticeOutOfPocketMember,
  isMember,
  isPaypalStandardPaymentCountries,
  isProvider,
  isTherapist,
  showPayLaterOptions,
  titleCaseName,
} from 'utils';

import {durationEventColor} from '../../Provider/Calendar2/constants';
import {selectDueAppointments} from '../appointmentSelectors';
import PaymentRequestModal from '../Charge/PaymentRequestModal';
import SendPaymentRequestModal from '../SendPaymentRequest';

import {CalendarItemProps, currencies, labelType} from './CalendarItemProps';

import 'react-day-picker/lib/style.css';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);
export const CalendarItemCardV3: FC<CalendarItemProps> = ({
  startTime,
  duration,
  patientFirstName,
  patientId,
  user,
  patientLastName,
  calendar,
  appointmentID,
  patientDetails,
  canceled,
  participants,
  notes,
  localTime,
  isPastEvent,
  category,
  ...props
}) => {
  const {t} = useTranslation();
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [skip, setskip] = useState(false);
  const [showSendPaymentRequestModal, setShowSendPaymentRequestModal] =
    useState(false);
  const [onSuccessModal, setOnSuccessModal] = useState(false);

  const isObsoleteEvent = isPastEvent?.(localTime);
  const {medicalTeam} = useProfile();
  const providerData =
    medicalTeam && medicalTeam.find(team => team.fullName === calendar);
  const dueAppointments: number[] = useSelector(selectDueAppointments);

  const meetingDate = props.meeting?.endDate
    ? dayjs(props.meeting.endDate)
    : dayjs();
  const date = meetingDate.date();
  const month = meetingDate.format('MMMM');
  const day = meetingDate.format('dddd');

  const isPaypalStandardMember =
    user?.countryOfResidence?.code &&
    isPaypalStandardPaymentCountries(
      user.countryOfResidence.code as PaypalStandardPaymentCountries,
    );

  let participantContent;
  if (props.isGroupCall && isTherapist(user)) {
    participantContent = (
      <>
        <button
          className="pb-1 cursor-pointer"
          onClick={() => setShowParticipantsModal(true)}
        >
          <span className="text-blue-600 hover:underline">
            <Trans
              i18nKey="calendarItemCard.participants"
              values={{participants: `${participants?.length ?? 0}`}}
            />
          </span>
        </button>
        <p className="font-light">
          {notes} &bull;&nbsp;
          {startTime}
        </p>
      </>
    );
  } else {
    participantContent = (
      <p className="">
        {titleCaseName(
          isProvider(user) ? '' : t(`appointmentCategory.${category}`),
        )}{' '}
        &bull;&nbsp;
        {startTime}
      </p>
    );
  }

  return (
    <section
      data-testid="calendar-item"
      className="mb-4 rounded-2xl border text-sm font-inter p-3 bg-white flex-column items-center"
    >
      {isProvider(user) && !patientId ? (
        <p className="text-red-600/90 mb-3 bg-red-600/10 leading-5 py-2 px-3 text-center">
          {t('patientAccountRegistrationPending')}
        </p>
      ) : null}

      {isProvider(user) &&
      isDigitalPracticeOutOfPocketMember(patientDetails) &&
      isPaypalStandardMember &&
      props?.chargeStatus !== ChargeStatus.charged &&
      props.meeting?.paymentRequestedAt ? (
        <div className="w-full mb-3 space-y-1">
          <p className="text-red-600 text-xs font-light">
            {t('chargeAppointments.paymentPending.title')}
          </p>
          <p className="text-xs text-gray-600 font-light">
            {t('chargeAppointments.paymentPending.description', {
              paymentRequestedAt: dayjs(
                props?.meeting?.paymentRequestedAt,
              ).format(`MMM D, YYYY [${t('at')}] h:mm A`),
            })}
          </p>
        </div>
      ) : null}
      <div className="w-full flex items-center">
        <div className="flex flex-col items-center gap-2">
          <p className="text-base">{day}</p>
          <p className="text-4xl font-semibold">{date}</p>
          <p className="text-base">{month}</p>
        </div>
        <div className="ml-6 overflow-auto w-full">
          <div className="font-medium flex flex-row justify-between items-start gap-2">
            <div className="text-gray-700 text-left flex-grow leading-snug ">
              {props.isGroupCall ? (
                <div className="leading-snug font-normal">
                  {t('groupSession')}
                </div>
              ) : (
                <div className="flex flex-column">
                  <img
                    className="w-10 mr-2 h-10 border rounded-full object-cover"
                    src={
                      patientDetails?.image
                        ? patientDetails?.image
                        : providerData?.image
                        ? providerData?.image
                        : PlaceholderImage
                    }
                    alt="Avatar"
                  />
                  {isMember(user) ? (
                    <div>
                      <p>{providerData?.fullName} </p>
                      <div className="text-gray-500">
                        {providerData?.role
                          ? t(providerData.role.toLowerCase())
                          : ''}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p>
                        {patientFirstName} {patientLastName}{' '}
                      </p>
                      <div className="text-gray-500">
                        {t('patient', 'Patient')}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="mt-4">
                {props.isGroupCall && isMember(user) ? (
                  <>
                    <p className="pb-1 cursor-pointer">{calendar}</p>
                    <p className="font-light">
                      {notes} &bull;&nbsp;
                      {startTime}
                    </p>
                  </>
                ) : (
                  participantContent
                )}
              </div>
            </div>
            <div className="text-left hidden lg:block md:block">
              <p
                className="py-2 rounded-full whitespace-nowrap text-sm text-primary/80 bg-primary/10 gray-700 px-3 flex items-center"
                style={durationEventColor[duration!]}
              >
                <AvailabilityIconV2 height={20} strokeColor="#2E62EC" />
                &nbsp;
                <Trans
                  t={t}
                  i18nKey="durationMinutes"
                  values={{duration: `${duration}`}}
                />
              </p>
            </div>
          </div>
          <div className="font-medium text-gray-700 leading-snug w-full flex text-sm flex-row items-start pt-4 gap-2">
            {props.shouldShowCancelButton && !canceled && !isObsoleteEvent ? (
              <Button
                onClick={props.handleCancel}
                btnType="custom"
                textColor="gray-700"
                borderRadius="full"
                borderColor="gray-300"
                bgColor="white"
                className="py-2 h-10 items-center text-sm flex px-4 bg-white hover:bg-primary hover:text-white"
              >
                <span>{t('cancel', 'Cancel')}</span>
              </Button>
            ) : null}
            <section className="flex w-full items-start gap-2">
              {isProvider(user) && !patientId ? null : canceled &&
                isMember(user) ? (
                <p className="text-red-500 font-bold ">
                  {t('canceled', 'Canceled')}
                </p>
              ) : (
                <>
                  {props.isGroupCall && isMember(user) ? (
                    <Button
                      type="button"
                      borderRadius="full"
                      borderColor="transparent"
                      fontSize="sm"
                      className="w-full py-2 flex justify-center items-center"
                      onClick={props.updateCallInterest}
                      disabled={
                        props.isJoinedInGroupCall || props.loadingState.interest
                      }
                    >
                      {props.loadingState.interest ? (
                        <LoadingSpinner
                          color="#999"
                          type="Oval"
                          width={12}
                          height={12}
                        />
                      ) : props.isInterestedInGroupCall ? (
                        t('notInterested')
                      ) : (
                        t('interested')
                      )}
                    </Button>
                  ) : null}
                  {!isObsoleteEvent ? (
                    <Button
                      type="button"
                      borderRadius="full"
                      borderColor="blue-600"
                      bgColor="white"
                      outline
                      fontSize="sm"
                      className="py-2 text-blue-600 text-sm px-3 bg-white flex justify-center items-center whitespace-nowrap"
                      data-testid="join"
                      disabled={
                        props.isGroupCallNotAllowed ||
                        props.loadingState.joining
                      }
                      onClick={props.onJoinClick}
                      tooltipContent={
                        props.isGroupCallNotAllowed
                          ? t(
                              'submitInterest',
                              'To join the appointment, please submit your interest first.',
                            )
                          : ''
                      }
                    >
                      {props.loadingState.joining ? (
                        <LoadingSpinner
                          color="#999"
                          type="Oval"
                          width={12}
                          height={12}
                        />
                      ) : (
                        t('joinCall', 'Join Call')
                      )}
                    </Button>
                  ) : null}

                  {/* adding the set price button for Digital Practice Appointments*/}
                  {isTherapist(user) &&
                  isDigitalPracticeOutOfPocketMember(patientDetails) &&
                  !isPaypalStandardMember ? (
                    props.price &&
                    props.chargeStatus !== ChargeStatus.charged ? (
                      <Button
                        type="button"
                        borderRadius="full"
                        btnType="custom"
                        borderColor="lime-600"
                        bgColor="white"
                        fontSize="sm"
                        className="py-2 px-3 bg-[#FF8DC1] hover:bg-white hover:text-[#FF8DC1] flex whitespace-nowrap text-white border-[#FF8DC1]"
                        onClick={props.onShowChargeModal}
                        data-testid="set-price"
                      >
                        <CurrencyDollarIcon aria-hidden="true" />
                        &nbsp;{t('charge', 'Charge')}
                      </Button>
                    ) : props.price &&
                      props.chargeStatus === ChargeStatus.charged ? (
                      <div className="w-full">
                        <span className="bg-[#ED82B3]/10 text-[#ED82B3] rounded-full px-3 float-right py-2">
                          <CircleCheckIcon height={24} width={24} />
                        </span>
                      </div>
                    ) : (
                      <Button
                        type="button"
                        borderRadius="full"
                        borderColor="transparent"
                        fontSize="sm"
                        className="py-2 px-3 flex whitespace-nowrap"
                        data-testid="charge"
                        onClick={props.onShowChargeModal}
                      >
                        <CurrencyDollarIcon aria-hidden="true" />
                        &nbsp;{t('setPrice', 'Set Price')}
                      </Button>
                    )
                  ) : null}

                  {/* adding the set price button for the Paypal Standard Digital Practice Appointments*/}
                  {isTherapist(user) &&
                  isDigitalPracticeOutOfPocketMember(patientDetails) &&
                  isPaypalStandardMember ? (
                    props.price &&
                    props.chargeStatus === ChargeStatus.charged ? (
                      <div className="w-full">
                        <span className="bg-[#ED82B3]/10 text-[#ED82B3] rounded-full px-3 float-right py-2">
                          <CircleCheckIcon height={24} width={24} />
                        </span>
                      </div>
                    ) : (
                      <Button
                        type="button"
                        borderRadius="full"
                        borderColor="[#FF8DC1]"
                        bgColor="[#FF8DC1]"
                        fontSize="sm"
                        btnType="custom"
                        className="h-10 px-3 border hover:bg-white hover:text-[#FF8DC1] flex justify-center items-center whitespace-nowrap"
                        data-testid="charge"
                        onClick={() => setShowSendPaymentRequestModal(true)}
                      >
                        {t('paymentRequest.paymentReq')}
                      </Button>
                    )
                  ) : null}
                </>
              )}
            </section>
            {/* modals  */}
            <ChargeModal
              user={user}
              currencies={currencies}
              price={Number(props.price)}
              showChargeModal={props.showChargeModal}
              setShowChargeModal={props.setShowChargeModal}
              appointmentID={appointmentID.toString()}
              onChargeClick={props.onChargeClick}
              charged={props.chargeStatus}
              isLoading={props.isLoading}
              setSkip={setskip}
              skip={skip}
              setOnCallPricingSkip={props.setOnCallPricingSkip}
              setShowSuccessModal={props.setShowSuccessModal}
              showSuccessModal={props.showSuccessModal}
              patientId={patientId || ''}
              localTime={localTime}
            />

            {/* international provider send payment request to the patient (South Africa and Columbia) */}
            <SendPaymentRequestModal
              user={user}
              patientId={patientId || ''}
              patientName={`${patientFirstName} ${patientLastName}`}
              // this should be `USD` because we're using PayPal express checkout
              currency="USD"
              appointmentID={appointmentID.toString()}
              price={Number(props.price)}
              showRequestModal={showSendPaymentRequestModal}
              setShowRequestModal={setShowSendPaymentRequestModal}
              isLoading={props.isLoading}
              onSuccessModal={onSuccessModal}
              setOnSuccessModal={setOnSuccessModal}
              startDate={dayjs(localTime).toISOString()}
            />

            {/* It's for Member */}
            <PaymentRequestModal
              user={user}
              currencies={currencies}
              price={Number(props.price)}
              showPaymentRequestModal={props.showPaymentRequestModal}
              setShowPaymentRequestModal={props.setShowPaymentRequestModal}
              appointmentID={appointmentID.toString()}
              charged={props.chargeStatus}
              duration={duration}
              isLoading={props.isLoading}
              setShowSuccessModal={props.setShowSuccessPaymentModal}
              showSuccessModal={props.showSuccessPaymentModal}
              isPaymentDue={
                dueAppointments?.length > 0 &&
                dueAppointments.includes(appointmentID)
              }
              patientId={patientId || ''}
              localTime={localTime}
              {...(localTime && duration && props.meeting?.appointmentCharge
                ? {
                    showPayLater: showPayLaterOptions(
                      localTime.toISOString(),
                      duration!,
                    ),
                  }
                : {})}
              videoLink={props.videoLink}
            />
            {props.isGroupCall &&
            isTherapist(user) &&
            Array.isArray(participants) ? (
              <Modal
                title={t(
                  'groupAppointmentParticipants',
                  'Group Appointment Participants',
                )}
                isOpen={showParticipantsModal}
                buttonFn={() => setShowParticipantsModal(false)}
                messageType="none"
                showCloseIcon
              >
                {participants.map((participant: any, idx: number) => (
                  <div key={+idx} className="w-full flex justify-between py-4">
                    <div className="flex items-center space-x-3">
                      <AccountIcon />
                      <p className="text-lg">
                        {participant.firstName} {participant.lastName}
                      </p>
                    </div>
                    {participant.labels === null ? (
                      <p className="text-gray-600">
                        {t('notApplicable', 'N/A')}
                      </p>
                    ) : (
                      labelType(t)[participant.labels[0]?.name]
                    )}
                  </div>
                ))}
              </Modal>
            ) : null}
            {props.showApptError.show ? (
              <Modal
                title="time warning"
                messageType="warning"
                isOpen={props.showApptError.show}
                buttonFn={props.onCloseApptTooEarlyModal}
                showCloseIcon
              >
                <div className="flex flex-col items-center justify-center space-y-10 max-w-lg mx-auto mb-8">
                  <div
                    className="px-5 text-center text-xl"
                    dangerouslySetInnerHTML={{
                      __html: props.showApptError.message,
                    }}
                  />
                  <Button
                    btnType="primary"
                    borderRadius="full"
                    className="w-full max-w-[250px] mx-auto py-3 leading-snug uppercase"
                    onClick={props.onCloseApptTooEarlyModal}
                  >
                    {t('close', 'CLOSE')}
                  </Button>
                </div>
              </Modal>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};
