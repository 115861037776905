import React, {FC, lazy, Suspense, useState} from 'react';
import classnames from 'classnames';
import {Button, CloseIcon, WarningTriangleIcon} from 'components';
import {isAfter} from 'date-fns';
import dayjs from 'dayjs';
import {CurrencyExchangeRateForNonPaypalCountries} from 'features/Currency/components';
import {CurrentUser} from 'interfaces';
import {ChargeStatus} from 'interfaces/Meeting.types';
import {useTranslation} from 'react-i18next';
import {Redirect, useHistory} from 'react-router-dom';
import {componentLoader, getProviderCurrency} from 'utils';

import {dispatch} from '../../../app/store';
import Toast from '../../../components/Basic/Toast';

import {payLaterRequest, standardCheckoutChargeSuccess} from './chargeActions';
import {PaypalButtons} from './PaypalButtons';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

type Options = {
  value: string;
  label: string;
};

type PaymentRequestModalProps = {
  currencies?: Options[];
  price: number;
  appointmentChargeCurrency?: string;
  showPaymentRequestModal: boolean;
  videoLink?: string;
  setShowPaymentRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
  user: CurrentUser;
  showSuccessModal: boolean;
  setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentID: string;
  charged?: ChargeStatus;
  isLoading?: boolean;
  duration: string | undefined;
  patientId: string;
  localTime: Date;
  isPaymentDue?: boolean;
  showPayLater?: boolean;
  isAlreadyPaid?: boolean;
};

interface ModalContentProps {
  title: string;
  description: string;
  providerCurrency: string;
  countryCode: string;
  price: number;
  actions: JSX.Element[];
  hint?: string;
  showWarning?: boolean;
  allowCloseModal?: boolean;
  closeModal?: () => void;
}

function getDayWithSuffix(day: number) {
  const j = day % 10;
  const k = day % 100;
  if (j === 1 && k !== 11) {
    return day + 'st';
  }
  if (j === 2 && k !== 12) {
    return day + 'nd';
  }
  if (j === 3 && k !== 13) {
    return day + 'rd';
  }
  return day + 'th';
}

const ModalContent = (props: ModalContentProps) => {
  return (
    <>
      {props?.allowCloseModal ? (
        <div
          className={classnames(`w-full mb-1 flex justify-end items-center`)}
        >
          <button
            className="ml-auto rounded-full focus:outline-none bg-gray-100 w-8 h-8 flex items-center justify-center p-0.5 text-gray-500 hover:text-gray-700"
            onClick={props.closeModal}
            key="closeButton"
          >
            <CloseIcon
              strokeColor="text-inherit"
              strokeWidth={30}
              width={22}
              height={22}
            />
          </button>
        </div>
      ) : null}
      <div className="flex flex-col w-full items-center p-4 pb-8">
        {props?.showWarning ? (
          <WarningTriangleIcon classes="w-10 h-10 mb-2" />
        ) : null}
        <p className="font-semibold text-xl mb-2">{props.title}</p>
        <div className="mb-2 flex flex-col text-sm items-center justify-center max-w-xs">
          <p className="mb-2 text-center px-5">{props.description}</p>

          <input
            className="rounded-lg text-center font-medium py-3 my-6 px-3 focus:outline-none focus:border-blue-600 focus:ring-blue-600"
            disabled={true}
            value={`${props.providerCurrency} ${props.price.toFixed(2)}`}
          />
        </div>
        {props?.price ? (
          <div className="w-full -mt-3 pb-7">
            <CurrencyExchangeRateForNonPaypalCountries
              countryCode={props.countryCode}
              value={props.price}
            />
          </div>
        ) : null}

        {Array.isArray(props.actions) && props.actions.length ? (
          <div className="flex flex-row w-full items-center justify-center gap-2">
            {props.actions}
          </div>
        ) : null}

        {props?.hint ? (
          <p className="text-center text-gray-600 italic">{props.hint}</p>
        ) : null}
      </div>
    </>
  );
};

export const PaymentRequestModal: FC<PaymentRequestModalProps> = ({
  showPaymentRequestModal,
  setShowPaymentRequestModal,
  user,
  price,
  appointmentID,
  duration,
  localTime,
  isLoading: isPayLaterLoading,
  showSuccessModal,
  setShowSuccessModal,
  videoLink,
  isPaymentDue,
  showPayLater,
  isAlreadyPaid,
}) => {
  const {t} = useTranslation();
  const countryCode = user?.countryOfResidence?.code || '';
  const providerCurrency = getProviderCurrency(countryCode);
  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);
  const history = useHistory();
  const payLaterAllowed = showPayLater && !isPaymentDue;

  const therapistId = user?.therapistDetails?._id;

  const closeModal = () => {
    setShowPaymentRequestModal(false);
  };

  const closePaymentMethodsModal = () => {
    setShowPaymentMethodsModal(false);
  };

  const meetingStartTime = localTime;
  const meetingEndTime = new Date(
    meetingStartTime.getTime() + Number(duration) * 60000,
  );
  const meetingStarted =
    isAfter(new Date(), meetingStartTime) &&
    !isAfter(new Date(), meetingEndTime);
  const doNotDisturbUser = !payLaterAllowed || meetingStarted;

  const onPaymentSuccess = () => {
    setShowSuccessModal(true);
    setShowPaymentMethodsModal(false);
    dispatch(
      standardCheckoutChargeSuccess({
        message: {
          appointmentID: Number(appointmentID),
          chargeStatus: ChargeStatus.charged,
        },
      }),
    );
  };

  const onPaymentError = (err: string) => {
    Toast({type: 'error', message: err});
    setShowPaymentMethodsModal(false);
  };

  const {message, messageType} = {
    messageType: 'success',
    message: t('chargeAppointments.paymentSuccess'),
  };

  const handlePaymentMethodClick = () => {
    setShowPaymentRequestModal(false);
    setShowPaymentMethodsModal(true);
  };

  const renderInitialModal = () => {
    if (isPaymentDue && isAlreadyPaid) {
      const modalContentProps = {
        title: t('chargeAppointments.paymentBlocked.title'),
        description: t('chargeAppointments.paymentBlocked.description'),
        providerCurrency: providerCurrency,
        price: price,
        countryCode,
        actions: [],
        hint: t('chargeAppointments.paymentBlocked.hint'),
        showWarning: true,
      };
      return <ModalContent {...modalContentProps} />;
    }

    if (isAlreadyPaid) {
      const modalContentProps = {
        title: t('chargeAppointments.paymentPaid.title'),
        description: t('chargeAppointments.paymentPaid.description'),
        providerCurrency: providerCurrency,
        price: price,
        countryCode,
        actions: [
          <Button
            className="min-w-[110px] h-11 flex items-center font-medium justify-center px-3"
            type="button"
            onClick={() => {
              closeModal();
              if (location.pathname.includes('/dashboard')) {
                history.push('/dashboard');
              }
            }}
            btnType="black"
            outline
          >
            {t('close', 'Close')}
          </Button>,
        ],
      };
      return <ModalContent {...modalContentProps} />;
    }

    if (isPaymentDue) {
      const dayjsDate = dayjs(localTime);
      const formattedDate = `${getDayWithSuffix(
        dayjsDate.date(),
      )} ${dayjsDate.format('MMMM YYYY [at] h:mmA')}`;

      const modalContentProps = {
        title: t('chargeAppointments.paymentDue.title'),
        description: t('chargeAppointments.paymentDue.description', {
          formattedDate: formattedDate,
        }),
        providerCurrency: providerCurrency,
        price: price,
        countryCode,
        actions: [
          <button
            className="h-11 min-w-[110px] flex items-center justify-center px-4 font-semibold rounded-lg border border-blue-600 bg-blue-600 text-white hover:text-blue-600 hover:bg-white"
            type="button"
            onClick={handlePaymentMethodClick}
          >
            {t('chargeAppointments.paymentMethod')}
          </button>,
        ],
      };

      return <ModalContent {...modalContentProps} />;
    }
    if (payLaterAllowed && videoLink) {
      const modalContentProps = {
        title: t('chargeAppointments.paymentRequest'),
        description: t('chargeAppointments.payForSessionText'),
        providerCurrency: providerCurrency,
        price: price,
        countryCode,
        actions: [
          <Button
            className="min-w-[110px] h-11 flex items-center font-medium justify-center px-3 rounded-lg border border-gray-400 text-gray-700 hover:text-white hover:bg-primary"
            type="button"
            onClick={() => {
              dispatch(
                payLaterRequest({
                  appointmentID: appointmentID,
                  callback: () => {
                    history.push(videoLink);
                  },
                  closeModal,
                  startDate: dayjs(localTime).toISOString(),
                }),
              );
            }}
            isSubmitting={isPayLaterLoading}
            loaderType="Oval"
            outline
          >
            {t('chargeAppointments.payLater')}
          </Button>,

          <button
            className="h-11 min-w-[110px] flex items-center justify-center px-4 font-semibold rounded-lg border border-blue-600 bg-blue-600 text-white hover:text-blue-600 hover:bg-white"
            type="button"
            onClick={handlePaymentMethodClick}
          >
            {t('chargeAppointments.paymentMethod')}
          </button>,
        ],
        allowCloseModal: true,
        closeModal,
      };
      return <ModalContent {...modalContentProps} />;
    } else {
      const modalContentProps = {
        title: t('chargeAppointments.paymentRequest'),
        description: t('chargeAppointments.payForSessionText'),
        providerCurrency: providerCurrency,
        price: price,
        countryCode,
        actions: [
          <Button
            className="min-w-[110px] h-11 flex items-center font-medium justify-center px-3"
            type="button"
            onClick={() => {
              closeModal();
              if (location.pathname.includes('/dashboard')) {
                history.push('/dashboard');
              }
            }}
            btnType="black"
            outline
          >
            {t('close', 'Close')}
          </Button>,

          <button
            className="h-11 min-w-[110px] flex items-center justify-center px-4 font-semibold rounded-lg border border-blue-600 bg-blue-600 text-white hover:text-blue-600 hover:bg-white"
            type="button"
            onClick={handlePaymentMethodClick}
          >
            {t('chargeAppointments.paymentMethod')}
          </button>,
        ],
      };
      return <ModalContent {...modalContentProps} />;
    }
  };

  if (showPaymentRequestModal && doNotDisturbUser && videoLink) {
    return <Redirect to={videoLink} />;
  }

  return (
    <>
      {showPaymentRequestModal && (
        <Suspense fallback={<div>Loading...</div>}>
          <Modal
            messageType="none"
            isOpen={showPaymentRequestModal}
            showCloseIcon={false}
            containerClasses="w-full max-h-[90vh] bg-white max-w-[445px] overflow-hidden transform rounded-2xl text-left align-middle transition-all shadow-lg font-inter"
            footerClasses="sticky bottom-0 bg-white"
            backdropClasses="bg-black/20"
            floatingTitle={false}
          >
            {renderInitialModal()}
          </Modal>
        </Suspense>
      )}

      {showPaymentMethodsModal && (
        <Suspense fallback={<div>Loading...</div>}>
          <Modal
            messageType="none"
            isOpen={showPaymentMethodsModal}
            containerClasses="w-full max-h-[90vh] bg-white max-w-[445px] overflow-hidden transform rounded-2xl text-left align-middle transition-all shadow-lg font-inter"
            footerClasses="sticky bottom-0 bg-white"
            height="700px"
            backdropClasses="bg-black/20"
            floatingTitle={false}
            buttonFn={closePaymentMethodsModal}
          >
            <div
              className={classnames(
                `w-full mb-3 flex justify-end items-center`,
              )}
            >
              <button
                className="ml-auto rounded-full focus:outline-none bg-gray-100 w-8 h-8 flex items-center justify-center p-0.5 text-gray-500 hover:text-gray-700"
                onClick={closePaymentMethodsModal}
                key="closeButton"
              >
                <CloseIcon
                  strokeColor="text-inherit"
                  strokeWidth={30}
                  onClick={closePaymentMethodsModal}
                  width={22}
                  height={22}
                />
              </button>
            </div>
            <div className="flex flex-col w-full items-center p-4 pb-8">
              <p className="font-semibold mb-2">
                {t('chargeAppointments.almostThere')}
              </p>
              <div className="mb-2 flex flex-col text-sm w-full items-center justify-center">
                <input
                  className="rounded-lg text-center font-medium py-3 my-6 px-3 focus:outline-none focus:border-blue-600 focus:ring-blue-600"
                  disabled={true}
                  value={`${providerCurrency} ${price.toFixed(2)}`}
                />

                <p className="mb-2 text-center">
                  {t('chargeAppointments.selectPaymentMethod')}
                </p>
              </div>
              <div className="flex flex-col w-full items-center justify-center">
                {therapistId ? (
                  <PaypalButtons
                    therapistId={therapistId}
                    appointmentID={appointmentID}
                    appointmentStartDate={dayjs(localTime).toISOString()}
                    appointmentEndDate={dayjs(localTime)
                      .add(Number(duration), 'minute')
                      .toISOString()}
                    onError={onPaymentError}
                    onSuccess={onPaymentSuccess}
                  />
                ) : null}
              </div>
            </div>
          </Modal>
        </Suspense>
      )}

      {showSuccessModal ? (
        <Suspense fallback={<div />}>
          <Modal
            message={message}
            messageType={messageType}
            isOpen={true}
            containerClasses="w-full w-[445px] max-h-[90vh] bg-white md:max-w-xl 2xl:max-w-2xl overflow-hidden transform rounded-2xl"
            backdropClasses="bg-black/30"
            buttonFn={() => {
              if (videoLink) {
                history.push(videoLink);
              }
              setShowSuccessModal(false);
            }}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default PaymentRequestModal;
