import {rootLogoutState} from 'app/initStore';
import {userActions} from 'features/User/userSlice';
import {
  Availability,
  AvailableProvider,
  SliceStatus,
  // TNotification,
} from 'interfaces';
import log from 'loglevel';

import {Dispatch} from '@reduxjs/toolkit';

import {AppCache} from './app-cache';

export const isIndexedDBAvailable = 'indexedDB' in window;

export const asyncRequestUserState = (
  dbName: string,
  dispatch: Dispatch,
): (() => Promise<void>) => {
  const handleStateLoadingError = () => {
    dispatch(
      userActions.stateLoadingDone({
        state: {
          user: {
            ...rootLogoutState.user,
            current: undefined,
          },
          notification: {
            ...rootLogoutState.notification,
            entities: {},
            ids: [],
          },
          appointment: {
            ...rootLogoutState.appointment,
          },
        },
        isCacheAvailable: false,
      }),
    );
  };
  return async (): Promise<void> => {
    if (!isIndexedDBAvailable) {
      handleStateLoadingError();
    }
    try {
      const db = new AppCache(dbName);
      await db.open();
      const user = await db.user.toCollection().last();
      // let notificationsIds: string[] = [];
      // let notificationsDict: Record<string, TNotification> = {};

      // let unreadCount = 0;
      // if (user) {
      //   const notifications = await db.notifications
      //     .orderBy('_id')
      //     .reverse()
      //     .limit(10)
      //     .toArray();
      //   const {ids, entities, unread} = notifications.reduce<{
      //     ids: string[];
      //     entities: Record<string, TNotification>;
      //     unread: number;
      //   }>(
      //     (acc, curr) => {
      //       acc.ids.push(curr._id);
      //       acc.entities[curr._id] = curr;
      //       acc.unread += curr.state === 'unread' ? 1 : 0;
      //       return acc;
      //     },
      //     {
      //       ids: [],
      //       entities: {},
      //       unread: 0,
      //     },
      //   );

      //   notificationsIds = ids;
      //   notificationsDict = entities;
      //   unreadCount = unread;
      // }

      dispatch(
        userActions.stateLoadingDone({
          state: {
            user: {
              current: user,
              status: SliceStatus.idle,
              logoutStatus: SliceStatus.idle,
              checkSessionStatus: SliceStatus.idle,
              paystackSubaccountStatus: SliceStatus.idle,
              oneTimePasswordVerificationStatus: SliceStatus.idle,
              error: '',
              pwaInstallEvt: null,
              notification: {
                message: '',
                messageType: 'none',
              },
              bootstrap: false,
              socialOnboardStage: 1,
              releaseNotes: {
                status: SliceStatus.idle,
                data: [],
              },
              employers: {
                data: [],
                offset: '',
                hasMore: false,
                status: SliceStatus.idle,
              },
              searchedEmployers: {
                data: [],
                offset: '',
                hasMore: false,
                status: SliceStatus.idle,
              },
            },
            notification: {
              entities: {},
              ids: [],
              error: '',
              status: SliceStatus.idle,
              unreadCount: 0,
            },
            appointment: {
              availabilitySlots: {
                slots: [],
                loading: SliceStatus.idle,
              },
              appointments: [],
              loading: SliceStatus.idle,
              status: SliceStatus.idle,
              error: '',

              availability: {} as Availability,
              providersLoadingStatus: SliceStatus.idle,
              slotsLoadingStatus: SliceStatus.idle,
              provider: {
                data: {} as AvailableProvider,
                status: SliceStatus.idle,
              },
            },
          },
          isCacheAvailable: true,
        }),
      );
    } catch (error) {
      handleStateLoadingError();
      log.warn(error);
    }
  };
};
