import React, {FC, lazy, Suspense, useEffect, useMemo, useState} from 'react';
import {RootState} from 'app/rootReducer';
import {Button, FormError, LoadingSpinner} from 'components';
import {FormSelect, FormTextInput} from 'components/Basic/Form/V2';
import dayjs from 'dayjs';
import {providerActions} from 'features/Provider';
import {selectMemberById} from 'features/Provider/Members/membersSelectors';
import {selectUserNotification, selectUserProfile} from 'features/User';
import {useQuery, useRequesting} from 'hooks';
import {useBooking} from 'hooks/useBooking';
import {
  AppointmentDurations,
  AppointmentTypes,
  MemberProfile,
  PrescriberProfile,
  SliceStatus,
  TherapistProfile,
  UserAccountType,
  UserRoles,
} from 'interfaces';
import {createPortal} from 'react-dom';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {componentLoader, isProvider} from 'utils';

import {getDirectBookingSelectedProvider} from '../../../DirectBooking/directBookingSelectors';

import {BookingCalendarV2} from './BookingCalendarV2';
import {BookingFormButtons} from './BookingFormButtons';
import {ClientChooser} from './ClientChooser';
import {TimeSelect} from './TimeSelect';

import 'react-day-picker/lib/style.css';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

type Props = {
  appointmentType: AppointmentTypes;
  member?: MemberProfile;
  duration?: AppointmentDurations;
  onCancel?: () => void;
  onSuccess?: () => void;
  footerRef?: React.RefObject<HTMLDivElement>;
  selectedDate?: dayjs.Dayjs;
};

type OptionType = {label: string; value: string};
// eslint-disable-next-line max-lines-per-function
export const BookingFormV2: FC<Props> = React.memo(
  ({
    appointmentType,
    duration,
    member,
    onCancel,
    footerRef,
    selectedDate,
    onSuccess,
  }) => {
    const history = useHistory();
    const user = useSelector(selectUserProfile);
    const {message, messageType, navigateTo} = useSelector(
      selectUserNotification,
    );
    const directBookingProvider = useSelector(getDirectBookingSelectedProvider);

    const isMemberDetailsLoading =
      useRequesting('provider') === SliceStatus.pending;

    const dispatch = useDispatch();
    const [memberId, setMemberId] = useState<string>();
    const location = useLocation<{id: string}>();
    const patientId = useQuery().get('patientId');

    const id = location?.state?.id;
    const memberPresentInRouterState = Boolean(
      location?.state?.id || patientId,
    );
    const getMemberId = memberId || id || patientId!;
    const memberById = useSelector((state: RootState) => {
      return selectMemberById(state, getMemberId);
    });

    const getProviderId = () =>
      (user as TherapistProfile)?.therapistId ||
      (user as PrescriberProfile)?.prescriberId;

    const {
      onSubmit,
      time,
      control,
      watch,
      register,
      errors,
      memberState,
      zones,
      availability,
      disabledDays,
      selectedTime,
      isLoading,
      bookingCharge,
      setValue,
      onConfirmBooking,
      closeExtraChargeModal,
      providerLoadingStatus,
      slotLoadingStatus,
      getProviderCalendarData,
    } = useBooking(
      appointmentType,
      duration!,
      member || memberById,
      selectedDate,
    );
    const {t} = useTranslation();

    const isPending =
      isLoading === SliceStatus.pending ||
      bookingCharge.status === SliceStatus.pending;
    const clientInputValue = watch('client');
    const timezoneValue = watch('timezone');

    const timezoneLabel = useMemo(() => {
      if (timezoneValue) {
        return zones?.find(z => z.value === timezoneValue)?.label ?? '';
      }
      return '';
    }, [timezoneValue, zones]);

    useEffect(() => {
      return () => {
        if (history.location.state && (history.location.state as any).id) {
          const state = {...(history.location.state as any)};
          delete state.id;
          history.replace({...history.location, state});
        }
      };
    }, []);

    useEffect(() => {
      if (isProvider(user) && memberPresentInRouterState) {
        dispatch(
          providerActions.getMemberById({
            patientId: id || patientId!,
            role: user!.role,
          }),
        );
      }
    }, [id, user!.role, memberPresentInRouterState]);

    useEffect(() => {
      if (
        isProvider(user) &&
        clientInputValue &&
        memberId &&
        !memberPresentInRouterState
      ) {
        dispatch(
          providerActions.getMemberById({
            patientId: clientInputValue,
            role: user!.role,
          }),
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientInputValue, memberId, user!.role, memberPresentInRouterState]);

    useEffect(() => {
      if (isProvider(user) && clientInputValue) {
        setValue('providerId', getProviderId());
      }
    }, [clientInputValue]);

    const [providerType, bookingType] = (() => {
      switch (appointmentType) {
        case AppointmentTypes.chat_with_coach:
          return ['Coach', 'Chat With Coach'];
        case AppointmentTypes.video_call_with_therapist:
          return ['Therapist', 'Video Call With Therapist'];
        default:
          return ['Doctor', 'Doctor Consultation'];
      }
    })();

    const providerOptions: OptionType[] = useMemo(() => {
      if (isProvider(user)) {
        return [
          {
            label: user?.fullName,
            value: user?.therapistId || user?.prescriberId,
          },
        ];
      }

      const mapProvider = (providerDetails: {
        acuity: {
          calendarId: number;
        };
        fullName: string;
        email: string;
        _id: string;
        prescriberId?: string;
        therapistId?: string;
      }) => ({
        label: providerDetails.fullName,
        value: providerDetails.prescriberId || providerDetails.therapistId,
      });

      const scaleTherapistCall =
        user?.accountType === UserAccountType.scale &&
        appointmentType === AppointmentTypes.video_call_with_therapist;

      if (scaleTherapistCall) {
        return availability && Array.isArray(availability?.providers)
          ? availability.providers
              .filter(p => p?.therapistId === user.therapistDetails.therapistId)
              .map(mapProvider)
          : [];
      }

      if (directBookingProvider) {
        return [
          {
            label: directBookingProvider.providerFullName ?? '',
            value: directBookingProvider.providerId,
          },
          ...availability.providers
            .filter(p => p?.therapistId !== directBookingProvider.providerId)
            .map(mapProvider),
        ];
      }

      return (
        availability?.providers?.map(x => ({
          label: x.fullName,
          value: x?.therapistId ?? x?.prescriberId ?? '',
          calendarId: x.acuity.calendarId,
        })) || []
      );
    }, [user, availability]);

    const [allowFetchOnMenuOpen, setAllowFetchOnMenuOpen] = useState(
      !!selectedDate,
    );

    const modalProps = {
      message,
      messageType,
      isOpen: true,
      buttonFn: () => {
        onSuccess?.();
        if (navigateTo) {
          history.push(navigateTo);
        }
      },
    };

    return (
      <>
        {messageType === 'none' || !isProvider(user) ? null : (
          <Suspense fallback={<div />}>
            <Modal {...modalProps} />
          </Suspense>
        )}
        <form
          onSubmit={onSubmit}
          className="mx-auto px-2 overflow-y-auto"
          id="booking-form"
        >
          {isProvider(user) ? null : (
            <h1 className="font-medium text-lg pt-5 md:pt-10 pb-4 my-0">
              {bookingType}
            </h1>
          )}
          <input
            type="text"
            className="hidden"
            {...register('appointmentType')}
          />

          {isProvider(user) && memberPresentInRouterState ? (
            <FormSelect
              control={control}
              id="client"
              label="Client"
              classes="mb-5"
              labelClasses="font-light text-xs"
              defaultValue={memberById?.patientId}
              extractValue={true}
              options={[
                {
                  value: memberById?.patientId,
                  label: memberById?.fullName,
                },
              ]}
            />
          ) : null}

          {isProvider(user) && !memberPresentInRouterState ? (
            <ClientChooser
              control={control}
              memberPresentInState={memberPresentInRouterState}
              onMemberSelect={id => setMemberId(id)}
            />
          ) : null}

          {isProvider(user) ? null : (
            <FormSelect
              control={control}
              id="providerId"
              defaultValue=""
              label={t('choose', {providerType})}
              extractValue={true}
              labelClasses="font-light text-xs"
              isLoading={providerLoadingStatus === SliceStatus.pending}
              options={isPending ? [] : providerOptions}
              loadingMessage={() =>
                `${t('loading', 'Loading')} ${providerType.toLowerCase()}...`
              }
              placeholder={t('choose_your', {providerType})}
              noOptionsMessage={e =>
                e.inputValue
                  ? t('no_options_with_input', {
                      providerType: providerType.toLowerCase(),
                      inputValue: e.inputValue,
                      defaultValue: t('no_options_with_input', {
                        providerType: providerType.toLowerCase(),
                        inputValue: e.inputValue,
                      }),
                    })
                  : t('no_options')
              }
            />
          )}

          <FormTextInput
            control={control}
            id="state"
            label={null}
            value={memberById?.stateOfResidence ?? memberState}
            classes="hidden"
          />

          <FormTextInput
            control={control}
            id="timezone"
            label={null}
            classes="hidden"
          />
          {timezoneLabel ? (
            <p className="capitalize text-xs font-light mt-2">
              {t('time_zone', 'Time Zone')} &bull; {timezoneLabel}
            </p>
          ) : null}

          {isProvider(user) ? (
            <>
              <div className="flex gap-2">
                <BookingCalendarV2
                  isAvailabilityLoading={
                    slotLoadingStatus === SliceStatus.pending
                  }
                  onMenuOpen={isMenuOpen => {
                    if (isMenuOpen && selectedDate && allowFetchOnMenuOpen) {
                      setAllowFetchOnMenuOpen(false);
                      // on date-picker open, fetch the availability for entire month rather than just the selected day
                      getProviderCalendarData(selectedDate.toDate(), false);
                    }
                  }}
                  disabledDays={disabledDays}
                  control={control}
                  disabled={!clientInputValue}
                  setValue={setValue}
                  onMonthChange={month => {
                    setAllowFetchOnMenuOpen(false);
                    // on month change, fetch the availability for entire month rather than just the selected day
                    getProviderCalendarData(month, false);
                  }}
                />

                <TimeSelect
                  time={time}
                  loadingStatus={slotLoadingStatus}
                  watch={watch}
                  control={control}
                  setValue={setValue}
                  selectedDate={selectedDate}
                  label={t('selectTime', 'Select time')}
                  isProvider={isProvider(user)}
                  errors={errors}
                ></TimeSelect>
              </div>
              {!clientInputValue ? (
                <FormError error={t('pleaseChooseClient')} />
              ) : null}
            </>
          ) : slotLoadingStatus === SliceStatus.pending ||
            isMemberDetailsLoading ? (
            <div className="w-full flex justify-center py-4">
              <LoadingSpinner type="Oval" height={20} />
            </div>
          ) : null}

          {footerRef?.current
            ? createPortal(
                <BookingFormButtons
                  appointmentType={appointmentType}
                  isPending={isPending}
                  selectedTime={selectedTime}
                  onCancel={onCancel}
                  disabled={
                    providerLoadingStatus === SliceStatus.pending ||
                    slotLoadingStatus === SliceStatus.pending
                  }
                />,
                footerRef.current,
              )
            : null}
        </form>

        {bookingCharge.showModal ? (
          <Modal
            messageType="none"
            isOpen={bookingCharge.showModal}
            buttonFn={closeExtraChargeModal}
          >
            <article className="flex flex-col items-center justify-center px-5">
              {user?.role === UserRoles.member ? (
                <p className="text-lg text-justify">
                  <Trans i18nKey="outOfTime">
                    Hey there! Seems like you have run out of time. The price
                    for the session selected is: $
                    {{amount: bookingCharge.amount}}. Once you click&nbsp;
                    <span className="font-semibold">Complete Booking</span>,
                    your card on file will be charged. If you have previously
                    canceled any appointments with more than 24hr notice in the
                    past 30 days, please Text: “Balance 24” to (415) 449-7796
                    for immediate resolution.
                  </Trans>
                </p>
              ) : (
                <p className="text-lg text-center">
                  {t('patientWillBeChargedConfirm')}
                </p>
              )}

              <section className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-12">
                <Button
                  type="button"
                  onClick={onConfirmBooking}
                  borderColor="transparent"
                  className="rounded-full px-8 py-3"
                >
                  {user?.role === UserRoles.member
                    ? t('complete_booking')
                    : t('yes_book')}
                </Button>
                <Button
                  type="button"
                  onClick={closeExtraChargeModal}
                  borderColor="transparent"
                  bgColor="red-600"
                  textColor="red-600"
                  className="rounded-full px-8 py-3 font-semibold"
                  btnType="danger"
                  outline
                >
                  {t('no_go_back')}
                </Button>
              </section>
            </article>
          </Modal>
        ) : null}
      </>
    );
  },
);
