import {FocusEventHandler} from 'react';
import classNames from 'classnames';
import {
  ControllerRenderProps,
  FieldError,
  FieldPath,
  FieldValues,
  SetFieldValue,
} from 'react-hook-form';

import {AbstractFormItemProps} from '../AbstractFormItem';

export const getTextInputClasses = (
  error?: FieldError,
  inputClasses?: string,
  bgColor?: string,
) => {
  return classNames(
    `${inputClasses} border border-gray-400 w-full p-3 leading-tight focus:outline-none rounded-md bg-${bgColor}`,
    {
      'text-red-500': error,
      'placeholder-red-500': error,
      'focus:border-primary': !error,
      'focus:border-red-500': error,
      'focus:shadow-outline-red': error,
      'focus:shadow-outline': !error,
    },
  );
};

export type InputType =
  | 'text'
  | 'password'
  | 'email'
  | 'tel'
  | 'checkbox'
  | 'number'
  | 'date'
  | 'time';

export type TextInputProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = AbstractFormItemProps<TFieldValues, TContext> & {
  type?: InputType;
  inputClasses?: string;
  bgColor?: string;
  value?: string;
  overrideValue?: string;
  placeholder?: string;
  ariaLabel?: string;
  showError?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
};

export type CheckBoxProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = TextInputProps<TFieldValues, TContext> & {
  setValue: SetFieldValue<TFieldValues>;
  onChangeHandler?: (
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<TFieldValues, FieldPath<TFieldValues>>,
  ) => void;
};
