import {
  CancellationValues,
  DigitalPracticeAccountTypes,
  EndPoints,
  HttpMethods,
  InsuranceImageFormData,
  MemberProfile,
  ProviderRole,
  StripePlanNames,
  StripePlanTypes,
  UserRoles,
} from 'interfaces';
import {Meeting} from 'interfaces/Meeting.types';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {
  attachQueryParameters,
  objectToQueryParams,
  toFormData,
  unwrapAPIError,
} from 'utils';

import {BankAccount, Card, loadStripe, Stripe, Token} from '@stripe/stripe-js';

import {
  getMonthlyAndTotalPlanPrice,
  getPaymentOrUpdatePlanRoute,
  getPlanPageName,
  getPlanPriceMeta,
} from './helpers';

let stripePromise: Promise<Stripe | null>;

const getStripe = (): Promise<Stripe | null> => {
  const stripeKey = process.env.REACT_APP_STRIPE_CLIENT_ID;
  if (!stripePromise && stripeKey) {
    stripePromise = loadStripe(stripeKey);
  }
  return stripePromise;
};

type AddCreditCardPayload = {
  token:
    | unknown
    | {
        id: string;
        object: 'token';
        bank_account?: BankAccount | undefined;
        card?: Card | undefined;
        client_ip: string | null;
        created: number;
        livemode: boolean;
        type: string;
        used: boolean;
      };
};

const addCreditCard = async (token?: Token): Promise<void> => {
  try {
    await requestHandler<{message: string}, AddCreditCardPayload>({
      method: HttpMethods.POST,
      url: EndPoints.AddCreditCard,
      data: {
        token: {...token},
      },
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const checkout = async ({
  referralCode,
  ...data
}: {
  token?: Token;
  referralCode?: string;
  couponCode: string;
  paymentPlan: StripePlanNames;
  __subPaymentPlan: StripePlanNames;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: referralCode
        ? (`${EndPoints.Checkout}?referral=${referralCode}` as unknown as EndPoints)
        : EndPoints.Checkout,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const digitalPracticeInsuranceCheckout = async (data: {
  token?: Token;
  companyName?: string;
  employerName?: string;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const {companyName, employerName, ...rest} = data;

    const url = attachQueryParameters(
      `${EndPoints.DigitalPracticeInsuranceCheckout}` as unknown as EndPoints,
      {companyName, employerName},
    );

    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: url as EndPoints,
      data: rest,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
const digitalPracticeOutOfPocketCheckout = async (data: {
  providerRole: ProviderRole;
  token: Token;
  digitalPracticeAccountType: DigitalPracticeAccountTypes;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: `${EndPoints.DigitalPracticeOutOfPocketCheckout}` as unknown as EndPoints,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const resubscribe = async (data: {
  token?: Token;
  paymentPlan: StripePlanNames;
  __subPaymentPlan: StripePlanNames;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.Resubscribe,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updatePlan = async (
  data: {
    paymentPlan:
      | StripePlanNames
      | StripePlanTypes.mindfulness
      | StripePlanTypes.uninsured
      | StripePlanTypes.therapy
      | StripePlanTypes.medicalcare_plus_therapy
      | StripePlanTypes.medicalcare
      | StripePlanTypes.new_therapy
      | StripePlanTypes.together;
    __subPaymentPlan:
      | StripePlanTypes.mindfulness
      | StripePlanTypes.uninsured
      | StripePlanTypes.therapy
      | StripePlanTypes.medicalcare_plus_therapy
      | StripePlanTypes.medicalcare
      | StripePlanTypes.new_therapy
      | StripePlanTypes.together;
  },
  idempotencyKey: string,
): Promise<void> => {
  try {
    await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.UpdatePlan,
      data,
      options: {
        headers: {
          'X-Idempotency-Key': idempotencyKey,
        },
      },
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const setDefaultCard = async (data: {cardId: string}): Promise<void> => {
  try {
    await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.SetDefaultCard,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const saveInsuranceImage = async (
  data: InsuranceImageFormData,
  onboardStage?: number,
  fromSocialOnboarding?: boolean,
): Promise<void> => {
  const formData = toFormData({
    insuranceImageBack: data.insuranceImageBack![0],
    insuranceImageFront: data.insuranceImageFront![0],
  });
  const url = attachQueryParameters(EndPoints.SaveInsuranceImage, {
    onboardStage,
    fromSocialOnboarding,
  });
  try {
    await requestHandler<{message: string}, typeof formData>({
      method: HttpMethods.POST,
      url: url as unknown as EndPoints,
      data: formData,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const annualPlanInterest = async (data: {
  paymentPlan: StripePlanTypes | StripePlanNames;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.AnnualPlanInterest,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const pauseSubscription = async (): Promise<Response<{message: number}>> => {
  try {
    const res = await requestHandler<{message: number}>({
      method: HttpMethods.POST,
      url: EndPoints.PauseSubscription,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const cancelSubscription = async (data: CancellationValues): Promise<void> => {
  try {
    await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.CancelSubscription,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const onboardingOneTimePayment = async (data: {
  providerType: 'doctor' | 'therapist';
  token?: Token;
}): Promise<void> => {
  try {
    await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.OnboardingOneTimePayment,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const setAppointmentPrice = async (data: {
  providerType?: UserRoles;
  appointmentID: string;
  charge: number;
  chargeCurrency: string;
  patientId: string;
  startDate: string;
}): Promise<Response<{message: Meeting}>> => {
  const {providerType, appointmentID, ...rest} = data;
  try {
    const res = await requestHandler<{message: Meeting}>({
      method: HttpMethods.POST,
      url: `/api/${providerType || 'therapist'}/${
        EndPoints.SetDigitalPracticeCharge
      }?appointmentID=${appointmentID}` as EndPoints,
      data: rest,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const chargeDigitalPracticeAppointment = async (data: {
  providerType: UserRoles;
  patientId: string;
  appointmentID: string;
  endDate: string;
  startDate: string;
}): Promise<Response<{message: Meeting}>> => {
  const {providerType, ...rest} = data;
  const queryParams = objectToQueryParams(rest);
  try {
    const res = await requestHandler<{message: Meeting}>({
      method: HttpMethods.POST,
      url: `/api/${providerType}/${EndPoints.ChargeDigitalPracticeAppointment}?${queryParams}` as EndPoints,
      data: {
        endDate: rest.endDate,
      },
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const sendPaymentRequest = async (data: {
  charge: number;
  chargeCurrency: string;
  appointmentID: string;
  patientId: string;
  startDate: string;
}): Promise<Response<{message: Meeting}>> => {
  try {
    const res = await requestHandler<{message: Meeting}>({
      method: HttpMethods.POST,
      url: EndPoints.SendPaymentRequest as EndPoints,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const StripeService = {
  getStripe,
  getMonthlyAndTotalPlanPrice,
  getPlanPriceMeta,
  getPaymentOrUpdatePlanRoute,
  getPlanPageName,
  checkout,
  resubscribe,
  addCreditCard,
  updatePlan,
  setDefaultCard,
  annualPlanInterest,
  pauseSubscription,
  cancelSubscription,
  saveInsuranceImage,
  onboardingOneTimePayment,
  digitalPracticeInsuranceCheckout,
  digitalPracticeOutOfPocketCheckout,
  setAppointmentPrice,
  chargeDigitalPracticeAppointment,
  sendPaymentRequest,
};
