import * as Yup from 'yup';

export const PersonalDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  providerPersonalEmailId: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .email('Invalid email')
    .required('Required'),
  providerPersonalPhoneNumber: Yup.string()
    .required('Required')
    .test('invalid', 'Phone number must be valid.', phone =>
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
        phone || '',
      ),
    ),
});

export const NpiNumberStatesSchema = Yup.object().shape({
  doYouHaveAnNpi: Yup.string().oneOf(['Yes', 'No']).required('Required'),
  providerNpi: Yup.string().when('doYouHaveAnNpi', {
    is: 'Yes',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  state: Yup.array()
    .of(Yup.object().shape({label: Yup.string(), value: Yup.string()}))
    .min(1, 'Required')
    .typeError('Required'),
});

export const ProviderNpiSchema = Yup.object().shape({
  providerNpi: Yup.string().required('Required'),
});

export const LicenseSchema = Yup.object().shape({
  providerLicenseType: Yup.array()
    .of(Yup.object().shape({label: Yup.string(), value: Yup.string()}))
    .min(1, 'Required')
    .typeError('Required'),
  providerStateLicenseNumber: Yup.string().required('Required'),
  supervisionStatus: Yup.string().required('Required'),
});

export const ProfessionalHeadshotSchema = Yup.object().shape({
  areYouReferredByAColleague: Yup.string().required('Required'),
  howDidYouHearAboutWellnite: Yup.string().when(
    'areYouReferredByAColleague',
    (areYouReferredByAColleague, schema) => {
      if (areYouReferredByAColleague === 'No') {
        return schema.required('Required');
      }

      return schema;
    },
  ),
  legalActions: Yup.string().required('Required'),
});
