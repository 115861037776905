import {TNotification} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const updateNotification = async (
  data: string,
): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}>({
      method: HttpMethods.POST,
      url: `${EndPoints.UpdateNotification}/?notificationId=${data}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const deleteNotification = async (
  data: string,
): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}>({
      method: HttpMethods.DELETE,
      url: `${EndPoints.UpdateNotification}/?notificationId=${data}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getNotifications = async ({
  page,
  limit,
}: {
  page: number;
  limit: number;
}): Promise<
  Response<{
    message: {notifications: TNotification[]; unreadCount?: number};
  }>
> => {
  try {
    const res = await requestHandler<{
      message: {notifications: TNotification[]; unreadCount?: number};
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetNotifications}/?page=${page}&limit=${limit}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const NotificationService = {
  updateNotification,
  deleteNotification,
  getNotifications,
};
