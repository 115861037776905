import React, {Dispatch, FC, useRef} from 'react';
import Modal from 'components/Basic/Modal';
import dayjs from 'dayjs';
import {AppointmentTypes, MemberProfile} from 'interfaces';
import {useTranslation} from 'react-i18next';

import {BookAppointment} from './BookAppointment/index3';

type Props = {
  show: boolean;
  onClose: () => void;
  appointmentType: AppointmentTypes;
  setShowBookingModal: Dispatch<React.SetStateAction<boolean>>;
  member: MemberProfile;
  selectedDate?: dayjs.Dayjs;
};

export const MemberBookingModal: FC<Props> = ({
  appointmentType,
  show,
  onClose,
  setShowBookingModal,
  member,
  selectedDate,
}: Props) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation();

  return (
    <Modal
      messageType="none"
      isOpen={show}
      showCloseIcon
      headerClasses="w-full p-6 flex justify-end items-center font-inter"
      containerClasses="w-full max-h-[90vh] bg-white max-w-[445px] overflow-hidden transform rounded-2xl text-left align-middle transition-all shadow-lg font-inter"
      titleHeight="80px"
      footerClasses="sticky bottom-0 bg-white"
      backdropClasses="bg-black/20"
      title={
        appointmentType === AppointmentTypes.doctor_consultation
          ? t('book_doctor_appointment')
          : t('book_therapy_appointment')
      }
      subTitle={
        appointmentType === AppointmentTypes.doctor_consultation
          ? t('durationMinutes', {duration: '10'})
          : undefined
      }
      floatingTitle={false}
      buttonFn={onClose}
      footer={<div className="w-full" ref={footerRef} />}
    >
      <BookAppointment
        selectedDate={selectedDate}
        appointmentType={appointmentType}
        member={member}
        setShowBookingModal={setShowBookingModal}
        footerRef={footerRef}
      />
    </Modal>
  );
};
