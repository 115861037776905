import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {FirebaseRefs} from 'definitions/Firebase';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {combineLatest, concat, from, Observable, of} from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {NotificationService} from 'services/api';
import {createObservableFromFirebase} from 'utils';

import {selectNotificationById} from './notificationSelectors';
import {notificationActions} from './notificationSlice';

const openNotificationEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(notificationActions.openNotificationState.match),
    mergeMap(({payload: {notificationId, entityId, changes}}) =>
      from(NotificationService.updateNotification(notificationId)).pipe(
        mergeMap(() => [
          notificationActions.notificationUpdated({
            id: entityId,
            changes,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'notification', message})),
            of(notificationActions.openNotificationStateFailure()),
          ),
        ),
      ),
    ),
  );

const openNotificationFailureEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(notificationActions.openNotificationStateFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.notification.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.notification.error}`});
    }),
    map(() => userActions.resetAsyncError('notification')),
    ignoreElements(),
  );

const deleteNotificationEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(notificationActions.deleteNotification.match),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: {notificationId, entityId},
        },
        state$,
      ]) => {
        const notification = selectNotificationById(state$, entityId);
        const optimisticDelete = of(
          notificationActions.notificationDeleted(entityId),
        );

        const apiCall = from(
          NotificationService.deleteNotification(notificationId),
        ).pipe(
          mergeMap(() => [notificationActions.notificationDeleted(entityId)]),
          catchError((message: string) =>
            concat(
              of(notificationActions.notificationFailedToDelete(notification)),
              of(userActions.setAsyncError({filter: 'notification', message})),
              of(notificationActions.deleteNotificationFailure()),
            ),
          ),
        );

        return concat(optimisticDelete, apiCall);
      },
    ),
  );

const deleteNotificationFailureEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(notificationActions.deleteNotificationFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.notification.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.notification.error}`});
    }),
    map(() => userActions.resetAsyncError('notification')),
    ignoreElements(),
  );

const getNotificationsEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(notificationActions.getNotifications.match),
    mergeMap(({payload}) =>
      from(NotificationService.getNotifications(payload)).pipe(
        mergeMap(({data: {message}}) => [
          notificationActions.notificationsReceived({
            ...message,
            page: payload.page,
          }),
          notificationActions.getNotificationsSuccess(),
        ]),
        catchError((message: string) =>
          concat(
            of(notificationActions.getNotificationsFailure()),
            of(userActions.setAsyncError({filter: 'notification', message})),
          ),
        ),
      ),
    ),
  );

const deleteReceivedNotificationEpic: Epic<
  AppActions,
  AppActions,
  RootState,
  {firebase: Observable<firebase.app.App>}
> = (action$, state$, {firebase}) =>
  action$.pipe(
    filter(notificationActions.deleteReceivedNotification.match),
    withLatestFrom(state$),
    mergeMap(([action, currState]) =>
      combineLatest([firebase, from([action.payload]), from([currState])]).pipe(
        map(([firebaseApp, payload, selectedState]) => ({
          firebaseApp,
          notificationId: payload,
          patientId: selectedState.user.current?.patientId || '',
        })),
      ),
    ),
    mergeMap(({firebaseApp, notificationId, patientId}) => {
      const ref = firebaseApp
        .database()
        .ref(FirebaseRefs.notifications)
        .child(patientId)
        .child(notificationId);
      return createObservableFromFirebase(ref.remove()).pipe(
        map(val => userActions.logData(val)),
        catchError(err => of(userActions.logData(err))),
      );
    }),
  );

export const notificationsEpics = [
  [
    openNotificationEpic,
    openNotificationFailureEpic,
    deleteNotificationEpic,
    deleteNotificationFailureEpic,
    getNotificationsEpic,
    deleteReceivedNotificationEpic,
  ],
].flatMap(epic => epic);
