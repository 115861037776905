import React, {FC} from 'react';
import {Button, LoadingSpinner} from 'components';
import dayjs, {Dayjs} from 'dayjs';
import {AppointmentTypes, AvailableProvider, MemberProfile} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {getCountryDetailsByIsoCode} from 'utils';

import 'react-day-picker/lib/style.css';

type Props = {
  member?: MemberProfile;
  appointmentType: AppointmentTypes;
  isPending: boolean;
  selectedTime: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  tooltipContent?: string;
  apptDetails?: {
    provider: AvailableProvider | undefined;
    date: Date;
    time: string;
    duration: number;
    isMember: boolean;
    isDPOOP: boolean;
  };
};

function formatTime(date: Dayjs) {
  return date.format('h:mm A');
}

export const BookingFormButtons: FC<Props> = ({
  // member,
  isPending,
  selectedTime,
  onSubmit,
  onCancel,
  disabled,
  tooltipContent,
  apptDetails,
}) => {
  const {t} = useTranslation();

  const $time = apptDetails?.time
    ?.replace('.', '')
    .replace('.', '')
    .toUpperCase();

  const apptDate = dayjs(apptDetails?.date);
  const startTime = dayjs(
    `${apptDate.format('YYYY-MM-DD')} ${$time}`,
    'YYYY-MM-DD h:mm A',
  );
  const endTime =
    apptDetails?.duration && startTime.add(apptDetails!.duration, 'minute');
  const apptTime = endTime
    ? `${formatTime(startTime)} - ${formatTime(endTime)}`
    : '';

  const providerCurrencyDetails = getCountryDetailsByIsoCode(
    apptDetails?.provider?.countryOfResidence?.code ?? 'US',
  );

  return (
    <>
      {apptDetails?.isMember &&
      apptDetails.isDPOOP &&
      apptDetails?.provider?.sessionRate &&
      apptDetails?.time ? (
        <section className="w-full grid grid-cols-2 justify-between items-center pb-7">
          <div className="flex flex-col items-start gap-1.5">
            <h5 className="font-inter text-base font-medium leading-5">
              {apptDate.format('ddd, MMMM D')}
            </h5>
            <p className="font-inter text-sm font-medium leading-4 text-gray-700">
              {apptTime}
            </p>
          </div>
          <div className="flex flex-col items-end gap-1.5">
            <h5 className="font-inter text-base font-medium leading-5">
              {providerCurrencyDetails?.currency}{' '}
              {providerCurrencyDetails?.currencySymbol}
              {apptDetails.provider.sessionRate?.digitalPractice.min}-
              {apptDetails.provider.sessionRate?.digitalPractice.max}
            </h5>
            <p className="font-inter text-sm font-medium leading-4 text-gray-700">
              {t('estimatedPrice', 'Estimated price')}
            </p>
          </div>
        </section>
      ) : null}
      <section className="flex w-full justify-end gap-2 text-center">
        <Button
          type="button"
          borderRadius="full"
          borderColor="blue-600"
          bgColor="white"
          onClick={onCancel}
          outline
          className="text-blue-600 bg-white min-w-[120px] rounded-full p-3"
        >
          {t('cancel')}
        </Button>
        <Button
          disabled={disabled || (Boolean(selectedTime) && isPending)}
          type="submit"
          form="booking-form"
          borderColor="transparent"
          className="min-w-[120px] rounded-full p-3 flex justify-center items-center"
          tooltipContent={tooltipContent}
          onClick={onSubmit}
        >
          {isPending ? (
            <LoadingSpinner type="Oval" height={20} />
          ) : (
            t('bookNow')
          )}
        </Button>
      </section>
    </>
  );
};
