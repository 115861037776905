import {
  AvailabilityData,
  SliceStatus,
  SpecificDayAvailabilityResponse,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  blockProviderAvailability,
  blockProviderAvailabilityFailure,
  blockProviderAvailabilitySuccess,
  getProviderAvailabilityHours,
  getProviderAvailabilityHoursFailure,
  getProviderAvailabilityHoursSuccess,
  resetProviderAvailabilitySaveStatus,
  saveProviderRecurringAvailability,
  saveProviderRecurringAvailabilityFailure,
  saveProviderRecurringAvailabilitySuccess,
  setIsAvailabilityModified,
  setSpecificDayAvailability,
  setSpecificDayAvailabilityFailure,
  setSpecificDayAvailabilitySuccess,
  unblockProviderAvailability,
  unblockProviderAvailabilityFailure,
  unblockProviderAvailabilitySuccess,
} from './availabilityActions';

type AvailabilitySliceState = {
  availability: AvailabilityData;
  status: SliceStatus;
  saveStatus: SliceStatus;
  error: string;
  isAvailabilityModified: boolean;
  specificDayAvailability: SpecificDayAvailabilityResponse;
};

const availabilitySlice = createSlice({
  name: 'availability',
  initialState: {
    availability: {},
    status: SliceStatus.idle,
    saveStatus: SliceStatus.idle,
    error: '',
    isAvailabilityModified: false,
    specificDayAvailability: {},
  } as AvailabilitySliceState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AvailabilitySliceState>) =>
    builder
      .addCase(getProviderAvailabilityHours, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(getProviderAvailabilityHoursSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        availability: action.payload,
        error: '',
      }))
      .addCase(getProviderAvailabilityHoursFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(saveProviderRecurringAvailability, state => ({
        ...state,
        saveStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(saveProviderRecurringAvailabilitySuccess, (state, action) => ({
        ...state,
        saveStatus: SliceStatus.resolved,
        availability: {
          ...state.availability,
          recurring: action.payload,
        },
        error: '',
      }))
      .addCase(saveProviderRecurringAvailabilityFailure, (state, action) => ({
        ...state,
        saveStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(resetProviderAvailabilitySaveStatus, state => ({
        ...state,
        saveStatus: SliceStatus.idle,
      }))
      .addCase(setIsAvailabilityModified, (state, action) => ({
        ...state,
        isAvailabilityModified: action.payload,
      }))
      .addCase(setSpecificDayAvailability, state => ({
        ...state,
        saveStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(setSpecificDayAvailabilityFailure, (state, action) => ({
        ...state,
        saveStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(setSpecificDayAvailabilitySuccess, state => ({
        ...state,
        saveStatus: SliceStatus.resolved,
      }))
      .addCase(blockProviderAvailability, state => ({
        ...state,
        saveStatus: SliceStatus.pending,
      }))
      .addCase(blockProviderAvailabilityFailure, state => ({
        ...state,
        saveStatus: SliceStatus.rejected,
      }))
      .addCase(blockProviderAvailabilitySuccess, state => ({
        ...state,
        saveStatus: SliceStatus.resolved,
      }))
      .addCase(unblockProviderAvailability, state => ({
        ...state,
        saveStatus: SliceStatus.pending,
      }))
      .addCase(unblockProviderAvailabilityFailure, (state, action) => ({
        ...state,
        saveStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(
        unblockProviderAvailabilitySuccess,
        (state, {payload: {blockId}}) => ({
          ...state,
          saveStatus: SliceStatus.resolved,
          availability: {
            ...state.availability,
            blocked: [
              ...state.availability.blocked.filter(item => item.id !== blockId),
            ],
          },
        }),
      ),
});

export const {reducer: availabilityReducer, name: availabilityReducerName} =
  availabilitySlice;

export type TAvailabilityActions =
  | ReturnType<typeof getProviderAvailabilityHours>
  | ReturnType<typeof getProviderAvailabilityHoursSuccess>
  | ReturnType<typeof getProviderAvailabilityHoursFailure>
  | ReturnType<typeof saveProviderRecurringAvailability>
  | ReturnType<typeof saveProviderRecurringAvailabilitySuccess>
  | ReturnType<typeof saveProviderRecurringAvailabilityFailure>
  | ReturnType<typeof blockProviderAvailability>
  | ReturnType<typeof blockProviderAvailabilityFailure>
  | ReturnType<typeof blockProviderAvailabilitySuccess>
  | ReturnType<typeof unblockProviderAvailability>
  | ReturnType<typeof unblockProviderAvailabilityFailure>
  | ReturnType<typeof unblockProviderAvailabilitySuccess>
  | ReturnType<typeof setSpecificDayAvailability>
  | ReturnType<typeof setSpecificDayAvailabilityFailure>
  | ReturnType<typeof setSpecificDayAvailabilitySuccess>;

export const availabilityActions = {
  getProviderAvailabilityHours,
  getProviderAvailabilityHoursSuccess,
  getProviderAvailabilityHoursFailure,
  resetProviderAvailabilitySaveStatus,
  setIsAvailabilityModified,
  blockProviderAvailability,
  blockProviderAvailabilityFailure,
  blockProviderAvailabilitySuccess,
  unblockProviderAvailability,
  unblockProviderAvailabilityFailure,
  unblockProviderAvailabilitySuccess,
  saveProviderRecurringAvailability,
  saveProviderRecurringAvailabilityFailure,
  saveProviderRecurringAvailabilitySuccess,
  setSpecificDayAvailability,
  setSpecificDayAvailabilityFailure,
  setSpecificDayAvailabilitySuccess,
};

export type AvailabilityState = ReturnType<typeof availabilityReducer>;
