import {
  CountryOfResidenceType,
  DigitalPracticeProvider,
  EndPoints,
  HttpMethods,
  MemberProfile,
  PrescriberProfile,
  ResultEmployersType,
  StripePlanNames,
  TherapistProfile,
  UserRoles,
} from 'interfaces';
import {Response} from 'redaxios';
import {attachQueryParameters, unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';

const getCheckSessionEndpoint = (role: UserRoles) => {
  let endpoint: EndPoints = EndPoints.MemberCheckSession;
  switch (role) {
    case UserRoles.admin:
      endpoint = EndPoints.AdminCheckSession;
      break;
    case UserRoles.mentalhealthcoach:
      endpoint = EndPoints.MentalHealthCoachCheckSession;
      break;
    case UserRoles.prescriber:
      endpoint = EndPoints.PrescriberCheckSession;
      break;
    case UserRoles.therapist:
      endpoint = EndPoints.TherapistCheckSession;
      break;
    default:
      endpoint = EndPoints.MemberCheckSession;
  }
  return endpoint;
};

const checkSession = async (
  role: UserRoles,
): Promise<
  Response<{message: MemberProfile | TherapistProfile | PrescriberProfile}>
> => {
  try {
    const res = await requestHandler<{
      message: MemberProfile | TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.GET,
      url: getCheckSessionEndpoint(role),
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const login = async (data: {
  email: string;
  password: string;
  keepSignedIn: boolean;
}): Promise<
  Response<{message: MemberProfile | TherapistProfile | PrescriberProfile}>
> => {
  try {
    const res = await requestHandler<
      {message: MemberProfile | TherapistProfile | PrescriberProfile},
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.Login,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const register = async ({
  referralCode,
  ...data
}: {
  fullName: string;
  stateOfResidence: string;
  email: string;
  password: string;
  phoneNumber: string;
  termsOfService: boolean;
  keepSignedIn: boolean;
  referralCode: string | undefined;
  referralSource: string;
  paymentPlan: StripePlanNames;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: referralCode
        ? (`${EndPoints.Register}?referral=${referralCode}` as unknown as EndPoints)
        : EndPoints.Register,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const outsideWellniteRegister = async ({
  providerId,
  ...data
}: {
  fullName: string;
  stateOfResidence: string;
  email: string;
  password: string;
  phoneNumber: string;
  termsOfService: boolean;
  keepSignedIn: boolean;
  providerId: string;
  paymentPlan: StripePlanNames;
  countryOfResidence: CountryOfResidenceType;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: providerId
        ? (`${EndPoints.OutsideWellniteRegister}/${providerId}` as unknown as EndPoints)
        : EndPoints.OutsideWellniteRegister,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const logout = async (): Promise<void> => {
  try {
    await requestHandler<{message: string}>({
      method: HttpMethods.GET,
      url: EndPoints.MemberLogout,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
const logoutOtherRole = async (role: UserRoles): Promise<void> => {
  try {
    await requestHandler<{message: string}>({
      method: HttpMethods.GET,
      url: `/api/${role}/logout` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
const requestPasswordToken = async (data: {
  email: string;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.RequestPasswordToken,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const validatePasswordToken = async (data: {
  token: string;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}>({
      method: HttpMethods.POST,
      url: `${EndPoints.ValidatePasswordToken}/${data.token}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changePassword = async (data: {
  newPassword: string;
  confirmPassword: string;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.ChangePassword,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const requestOtpCode = async (
  phoneNumber?: string,
): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}>({
      method: HttpMethods.GET,
      url:
        phoneNumber && phoneNumber.trim() !== ''
          ? (`${
              EndPoints.RequestRegistrationPhoneOtpCode
            }?phoneNumber=${encodeURIComponent(
              phoneNumber,
            )}` as unknown as EndPoints)
          : (`${EndPoints.RequestOtpCode}` as unknown as EndPoints),
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
export const updatePhoneAndResendOtpCode = async (
  phoneNumber: string,
): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}>({
      method: HttpMethods.POST,
      url: EndPoints.UpdatePhoneAndResendOtpCode,
      data: {
        phoneNumber,
      },
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const verifyOtpCode = async (
  verificationCode: string,
  newPhoneNumber: string,
  onboardStage?: number,
): Promise<Response<{message: MemberProfile}>> => {
  try {
    let url: EndPoints;
    if (!onboardStage && newPhoneNumber.startsWith('+')) {
      url = `${
        EndPoints.VerifyRegistrationPhoneOtpCode
      }/?verificationCode=${verificationCode}&phoneNumber=${encodeURIComponent(
        newPhoneNumber,
      )}` as unknown as EndPoints;
    } else {
      url = `${
        EndPoints.VerifyOtpCode
      }/?verificationCode=${verificationCode}&phoneNumber=${newPhoneNumber}&onboardStage=${
        onboardStage ?? ''
      }` as unknown as EndPoints;
    }

    const res = await requestHandler<{message: MemberProfile}>({
      method: HttpMethods.GET,
      url: url,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const getDigitalPracticeProviderData = async (
  providerId: string,
  isReferralLink?: boolean,
): Promise<Response<{message: any}>> => {
  try {
    const url = attachQueryParameters(
      `${EndPoints.GetDigitalPracticeProviderData}/${providerId}`,
      {isReferralLink: isReferralLink?.toString()},
    );

    const res = await requestHandler<{message: DigitalPracticeProvider}>({
      method: HttpMethods.GET,
      url: url as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getEmployers = async (
  size: number,
  offset?: string,
  searchKeyword?: string,
): Promise<
  Response<{
    message: ResultEmployersType;
  }>
> => {
  try {
    const url = attachQueryParameters(
      `${EndPoints.GetEmployers}?size=${size}`,
      {size, offset, searchKeyword},
    );

    const res = await requestHandler<{message: ResultEmployersType}>({
      method: HttpMethods.GET,
      url: url as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const AuthService = {
  login,
  register,
  outsideWellniteRegister,
  checkSession,
  logout,
  requestPasswordToken,
  validatePasswordToken,
  changePassword,
  logoutOtherRole,
  requestOtpCode,
  updatePhoneAndResendOtpCode,
  verifyOtpCode,
  getDigitalPracticeProviderData,
  getEmployers,
};
