import {SliceStatus} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const requestOneTimePassword = createAction('RequestOneTimePassword');
export const requestPhoneOTP = createAction<string>('RequestPhoneOTP');
export const changePhoneVerificationStage = createAction<{
  stage: number;
  cb: (s: SliceStatus, openInsuranceModal: boolean) => void;
}>('ChangePhoneVerificationStage');

export const requestOneTimePasswordSuccess = createAction(
  'RequestOneTimePasswordSuccess',
);
export const requestOneTimePasswordFailure = createAction(
  'RequestOneTimePasswordFailure',
);

export const updatePhoneAndrequestOneTimePassword = createAction<{
  phoneNumber: string;
}>('UpdatePhoneAndRequestOneTimePassword');
export const updatePhoneAndrequestOneTimePasswordSuccess = createAction(
  'UpdatePhoneAndRequestOneTimePasswordSuccess',
);
export const updatePhoneAndrequestOneTimePasswordFailure = createAction(
  'UpdatePhoneAndRequestOneTimePasswordFailure',
);

export const verifyOneTimePassword = createAction<{
  verificationCode: string;
  newPhoneNumber: string;
  onboardStage?: number;
}>('VerifyOneTimePassword');
export const verifyOneTimePasswordSuccess = createAction(
  'VerifyOneTimePasswordSuccess',
);
export const verifyOneTimePasswordFailure = createAction(
  'VerifyOneTimePasswordFailure',
);
